import { notification } from "antd"
import { Reducer } from "redux"
import { POST_SIGNUP_FAILURE, POST_SIGNUP_REQUEST, POST_SIGNUP_SUCCESS } from "Redux/Constants"

const INIT_STATE = {
    Signup: false,
    loading: false,
    error: false
}

export const SignupReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case POST_SIGNUP_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            }
        case POST_SIGNUP_SUCCESS: {
            notification.success({
                message: "Please Verify using OPT Code!",
                description: action.payload.data.message
            })
            return {
                ...state,
                loading: false,
                Signup: action.payload.data,
                error: false
            }
        }
        case POST_SIGNUP_FAILURE: {
            notification.error({
                message: "Signup Failed!",
                description: action.payload.message
            })
            return {
                ...state,
                loading: false,
                error: true
            }
        }
        default:
            return state


    }
}
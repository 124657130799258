import { Reducer } from "redux"
import { GET_FAQ_FAILURE, GET_FAQ_REQUEST, GET_FAQ_SUCCESS } from "Redux/Constants"

const INIT_STATE = {
    FAQ: [],
    loading: false,
    error: false
}

export const FAQReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_FAQ_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_FAQ_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                FAQ: action?.payload?.data
            }
        case GET_FAQ_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload?.message
            }
        default:
            return state
    }
}
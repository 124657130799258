import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";

const RouteGuard = (props: any) => {
  const LoggedIn = useSelector((state: RootStateOrAny) => state.LoginReducers.Login);

  useEffect(() => {
    if (!LoggedIn) {
      props.history.push("/auth");
    }
  }, [LoggedIn, props.history]);

  return <div>{LoggedIn && props.children}</div>;
};
export default RouteGuard;

export const POST_SIGNUP_REQUEST = "POST_SIGNUP_REQUEST"
export const POST_SIGNUP_SUCCESS = "POST_SIGNUP_SUCCESS"
export const POST_SIGNUP_FAILURE = "POST_SIGNUP_FAILURE"

export const POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST"
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS"
export const POST_LOGIN_FAILURE = "POST_LOGIN_FAILURE"

export const TRIGGER_LOGOUT = "TRIGGER_LOGOUT"

export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST"
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS"
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE"

export const GET_EXCHANGERATES_REQUEST = "GET_EXCHANGERATES_REQUEST"
export const GET_EXCHANGERATES_SUCCESS = "GET_EXCHANGERATES_SUCCESS"
export const GET_EXCHANGERATES_FAILURE = "GET_EXCHANGERATES_FAILURE"

export const GET_PAYMENT_ACTIVITIES_REQUEST = "GET_PAYMENT_ACTIVITIES_REQUEST"
export const GET_PAYMENT_ACTIVITIES_SUCCESS = "GET_PAYMENT_ACTIVITIES_SUCCESS"
export const GET_PAYMENT_ACTIVITIES_FAILURE = "GET_PAYMENT_ACTIVITIES_FAILURE"

export const GET_PRIVACYPOLICY_REQUEST = "GET_PRIVACYPOLICY_REQUEST"
export const GET_PRIVACYPOLICY_SUCCESS = "GET_PRIVACYPOLICY_SUCCESS"
export const GET_PRIVACYPOLICY_FAILURE = "GET_PRIVACYPOLICY_FAILURE"

export const GET_TERMSANDCONDITIONS_REQUEST = "GET_TERMSANDCONDITIONS_REQUEST"
export const GET_TERMSANDCONDITIONS_SUCCESS = "GET_TERMSANDCONDITIONS_SUCCESS"
export const GET_TERMSANDCONDITIONS_FAILURE = "GET_TERMSANDCONDITIONS_FAILURE"

export const GET_FAQ_REQUEST = "GET_FAQ_REQUEST"
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS"
export const GET_FAQ_FAILURE = "GET_FAQ_FAILURE"

export const GET_BANK_LIST_REQUEST = "GET_BANK_LIST_REQUEST"
export const GET_BANK_LIST_SUCCESS = "GET_BANK_LIST_SUCCESS"
export const GET_BANK_LIST_FAILURE = "GET_BANK_LIST_FAILURE"

export const POST_POLIPAY_REQUEST = "POST_POLIPAY_REQUEST"
export const POST_POLIPAY_SUCCESS = "POST_POLIPAY_SUCCESS"
export const POST_POLIPAY_FAILURE = "POST_POLIPAY_FAILURE"


export const TRANSACTION_AMOUNT = "TRANSACTION_AMOUNT"
export const BANK_OR_BRANCH = "BANK_OR_BRANCH"
export const RECEIVING_AMOUNT = "RECEIVING_AMOUNT"
export const BANK_DEPOSIT_ID = "BANK_DEPOSIT_ID"
export const REMITTANCE_DEPOSIT_ID = "REMITTANCE_DEPOSIT_ID"

import {
  Alert,
  Button,
  Card,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Spin,
  Tabs,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { GetExchangeRatesRequest } from "./DashboardActions";
import AusFlag from "Assets/englishFlag.png";
import NepalFlag from "Assets/nepaliFlag.png";
import SendMoney from "./SendMoney/SendMoney";
import { SmileOutlined, UserAddOutlined } from "@ant-design/icons";
import { GetBankListRequest } from "./BankList/BankListActions";
import { ResetPoliPay } from "./SendMoney/PoliPayRedux/PoliPayActions";
import { API } from "Redux/Apis";
import { RouteChildrenProps } from "react-router";
import { GetLinkedBanksRequest } from "Views/LinkedBankAccounts/LinkedBankAccountsActions";
import { GetSavedRecipientsRequest } from "Views/SavedRecipients/SavedRecipientsActions";
import { GetSendLocationRequest } from "./SendLocationRedux/SendLocationActions";
import { useForm } from "antd/lib/form/Form";

interface DashboardProps {}

var colorCode: any = window.localStorage.getItem("localColorCode");
const retrivedColorCodes = JSON.parse(colorCode);

const Dashboard: React.FC<RouteChildrenProps> = ({ history }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [sendMoney, setSendMoney] = useState<boolean>(false);
  const [showAddRecipient, setShowAddRecipient] = useState<boolean>(false);
  const [bankId, setBankId] = useState<number>();
  const [kycStatus, setKycStatus] = useState("");

  useEffect(() => {
    const kycChecked = localStorage.getItem("kycChecked");
    API.get("/customer/get-profile")
      .then((res: any) => {
        localStorage.setItem("kycStatus", res.data.kycStatus);
        // const kycStatus = res.data.kycStatus;
        setKycStatus(res.data.kycStatus);
        // if (kycStatus === "SUBMITTED") {
        //   if (kycChecked === "no") {
        //     openNotification();
        //   }
        // }
      })
      .then((res) => {})
      .catch((err) => {});
  }, []);

  const openNotification = () => {
    notification.open({
      message: "Your KYC is not Verified",
      onClose: () => {
        localStorage.setItem("kycChecked", "yes");
      },
      onClick: () => {
        localStorage.setItem("kycChecked", "yes");
      },
      icon: <SmileOutlined style={{ color: "#f58c3a" }} />,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showSendMoney = () => {
    setSendMoney(true);
    dispatch(ResetPoliPay());
  };
  const dispatch = useDispatch();
  const DashboardReducers = useSelector(
    (state: RootStateOrAny) => state.DashboardReducers
  );
  const NotificationReducers = useSelector(
    (state: RootStateOrAny) => state.NotificationReducers
  );
  // const kycStatus = useSelector(
  //   (state: RootStateOrAny) => state.ProfileReducers.ProfileDetails.kycStatus
  // );

  const BankLists = useSelector(
    (state: RootStateOrAny) =>
      state.BankListReducers.BankLists.associatedBankList
  );

  useEffect(() => {
    if (!DashboardReducers?.ExchangeRates) {
      dispatch(GetExchangeRatesRequest());
    }
  }, [dispatch]);
  useEffect(() => {
    dispatch(GetExchangeRatesRequest());
  }, [NotificationReducers.Notifications.count]);

  useEffect(() => {
    dispatch(GetBankListRequest());
    dispatch(GetSendLocationRequest());
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
    if (kycStatus === "ADD_INFO_PENDING" || kycStatus === "REJECTED") {
      history.push("/KYC");
    }
  };
  const onCancel = () => {
    setShowAddRecipient(false);
  };
  const handleBankDetails = async (values: any) => {
    const bank = BankLists?.filter((bank: any) => bank.id == bankId);

    const valuesToSend = {
      ...values,
      bankId: bankId,
      locationId: bank[0]?.locationId,
    };
    try {
      const response = await API.post(
        "/customer/link-bank-account",
        valuesToSend
      );
      // console.log(response.data);
      if (response.status === 200) {
        notification.success({ message: "Add Bank Details Success!" });
        setShowAddRecipient(false);
        form.resetFields();
      }
    } catch (error: any) {
      notification.error({
        message: "Something went wrong!",
        description: error?.response?.data?.message,
      });
    }
  };
  const handleRecipientDetails = async (values: any) => {
    const valuesToSend = {
      ...values,
    };
    try {
      const response = await API.post("/recipient/add", valuesToSend);
      // console.log(response.data);
      if (response.status === 200) {
        await API.get("/recipient/get-all");
        dispatch(GetSavedRecipientsRequest());
        notification.success({ message: "Add Recipient Details Success!" });
        setShowAddRecipient(false);
        form1.resetFields();
      }
    } catch (error: any) {
      notification.error({
        message: "Something went wrong!",
        description: error?.response?.data?.message,
      });
    }
  };

  useEffect(() => {
    //dispatch(ResetPoliPay());
    dispatch(GetLinkedBanksRequest(1));
    dispatch(GetSavedRecipientsRequest());
  });
  const [form] = useForm();
  const [form1] = useForm();
  return (
    <div>
      {kycStatus === "SUBMITTED" && (
        <Alert
          type="info"
          message="Your KYC is submitted and is under Review."
        />
      )}

      <Card>
        <Modal visible={showAddRecipient} footer={false} onCancel={onCancel}>
          <Tabs defaultActiveKey="bankDetails">
            <Tabs.TabPane tab="Bank Details" key="bankDetails">
              <Form form={form} onFinish={handleBankDetails} layout="vertical">
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    { required: true, message: "First Name is required!" },
                  ]}
                >
                  <Input size="large" placeholder="First Name" />
                </Form.Item>
                <Form.Item label="Middle Name" name="middleName">
                  <Input size="large" placeholder="Middle Name" />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    { required: true, message: "Last Name is required!" },
                  ]}
                >
                  <Input size="large" placeholder="Last Name" />
                </Form.Item>
                <Form.Item
                  label="Address Line 1"
                  name="addressLineOne"
                  rules={[
                    { required: true, message: "Address Line 1 is required!" },
                  ]}
                >
                  <Input size="large" placeholder="Address Line 1" />
                </Form.Item>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[{ required: true, message: "City is required!" }]}
                >
                  <Input size="large" placeholder="City" />
                </Form.Item>
                <Form.Item
                  label="Mobile Number"
                  name="mobileNumber"
                  rules={[
                    { min: 9, message: "Min length is 9." },
                    { max: 15, message: "Max length is 15." },
                    { required: true, message: "Mobile Number is required!" },
                  ]}
                >
                  <Input
                    min={0}
                    type="number"
                    size="large"
                    placeholder="Mobile Number"
                  />
                </Form.Item>
                <Form.Item label="Email Address" name="emailAddress">
                  <Input size="large" placeholder="Email Address" />
                </Form.Item>
                <Form.Item
                  label="Select Province"
                  name="zone"
                  // rules={[
                  //   { required: true, message: "Select Province is required!" },
                  // ]}
                >
                  <Select placeholder="Select Province">
                    <Select.Option value={1}>Province 1</Select.Option>
                    <Select.Option value={2}>Province 2</Select.Option>
                    <Select.Option value={3}>Province 3</Select.Option>
                    <Select.Option value={4}>Province 4</Select.Option>
                    <Select.Option value={5}>Province 5</Select.Option>
                    <Select.Option value={6}>Province 6</Select.Option>
                    <Select.Option value={7}>Province 7</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Account Holder's Name"
                  name="accountHolderName"
                  rules={[
                    {
                      required: true,
                      message: "Account Holder's Name is required!",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Account Holder's Name" />
                </Form.Item>
                <Form.Item
                  label="Account Number"
                  name="accountNumber"
                  rules={[
                    { required: true, message: "Account Number is required!" },
                  ]}
                >
                  <Input size="large" placeholder="Account Number" />
                </Form.Item>
                <Form.Item
                  label="Select Bank"
                  name="bank"
                  rules={[{ required: true, message: "Bank is required!" }]}
                >
                  <Select
                    onSelect={(value: any, key: any) => setBankId(key.key)}
                    placeholder="Select Bank"
                  >
                    {BankLists?.map((bank: any) => {
                      return (
                        <Select.Option key={bank.id} value={bank.bankName}>
                          {bank.bankName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Branch"
                  name="branch"
                  rules={[{ required: true, message: "branch is required!" }]}
                >
                  <Input size="large" placeholder="Branch Name" />
                </Form.Item>
                <Form.Item
                  label="Reason For Sending"
                  name="reasonForSending"
                  rules={[
                    {
                      required: true,
                      message: "Reason For Sending is required!",
                    },
                  ]}
                >
                  <Select placeholder="Select Reason for Sending">
                    <Select.Option value="personal">Personal</Select.Option>
                    <Select.Option value="family">Family</Select.Option>
                    <Select.Option value="business">Business</Select.Option>
                    <Select.Option value="others">Others</Select.Option>
                  </Select>
                  {/* <Input size="large" placeholder="Reason for Sending" /> */}
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{
                      backgroundColor: `${retrivedColorCodes?.primaryColor}`,
                      color: `${retrivedColorCodes?.blackColor}`,
                    }}
                  >
                    Submit Bank Details
                  </Button>
                </Form.Item>
              </Form>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Recipient Details" key="recipientDetails">
              <Form
                form={form1}
                onFinish={handleRecipientDetails}
                layout="vertical"
              >
                <Form.Item
                  label="First Name"
                  name="recipientFirstName"
                  rules={[
                    { required: true, message: "First Name is required!" },
                  ]}
                >
                  <Input size="large" placeholder="First Name" />
                </Form.Item>
                <Form.Item label="Middle Name" name="recipientMiddleName">
                  <Input size="large" placeholder="Middle Name" />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="recipientLastName"
                  rules={[
                    { required: true, message: "Last Name is required!" },
                  ]}
                >
                  <Input size="large" placeholder="Last Name" />
                </Form.Item>
                <Typography.Text>
                  Please ensure your recipient's name entered here matches the
                  details on their government issued ID as they will need to
                  present it during collections. Any incorrect names cause a
                  delay when collecting funds.
                </Typography.Text>
                <Form.Item
                  label="Address Line 1"
                  name="addressLineOne"
                  rules={[
                    { required: true, message: "Address Line 1 is required!" },
                  ]}
                >
                  <Input size="large" placeholder="Address Line 1" />
                </Form.Item>
                <Form.Item
                  label="City"
                  name="recipientCity"
                  rules={[{ required: true, message: "City is required!" }]}
                >
                  <Input size="large" placeholder="City" />
                </Form.Item>
                <Form.Item
                  label="Select Province"
                  name="recipientZone"
                  rules={[{ required: true, message: "Province is required!" }]}
                >
                  <Select placeholder="Select Province">
                    <Select.Option value={1}>Province 1</Select.Option>
                    <Select.Option value={2}>Province 2</Select.Option>
                    <Select.Option value={3}>Province 3</Select.Option>
                    <Select.Option value={4}>Province 4</Select.Option>
                    <Select.Option value={5}>Province 5</Select.Option>
                    <Select.Option value={6}>Province 6</Select.Option>
                    <Select.Option value={7}>Province 7</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Mobile Number"
                  name="recipientMobileNumber"
                  rules={[
                    { min: 9, message: "Min length is 9." },
                    { max: 15, message: "Max length is 15." },
                    { required: true, message: "Mobile Number is required!" },
                  ]}
                >
                  <Input
                    type="number"
                    size="large"
                    placeholder="Mobile Number"
                  />
                </Form.Item>
                <Form.Item label="Email Address" name="recipientEmail">
                  <Input
                    type="email"
                    size="large"
                    placeholder="Email Address"
                  />
                </Form.Item>
                <Form.Item
                  label="Reason For Sending"
                  name="reasonForSending"
                  rules={[
                    {
                      required: true,
                      message: "Reason For Sending is required!",
                    },
                  ]}
                >
                  {/* <Input size="large" placeholder="Reason For Sending" /> */}
                  <Select placeholder="Select Reason for Sending">
                    <Select.Option value="personal">Personal</Select.Option>
                    <Select.Option value="family">Family</Select.Option>
                    <Select.Option value="business">Business</Select.Option>
                    <Select.Option value="others">Others</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{
                      backgroundColor: `${retrivedColorCodes?.primaryColor}`,
                      color: `${retrivedColorCodes?.blackColor}`,
                    }}
                  >
                    Submit Recipients Details
                  </Button>
                </Form.Item>
              </Form>
            </Tabs.TabPane>
          </Tabs>
        </Modal>
        {kycStatus !== "VERIFIED" && (
          <Modal
            closable={false}
            title="KYC not verified!"
            visible={isModalVisible}
            footer={[<Button onClick={handleOk}>OK</Button>]}
          >
            {kycStatus === "" ? (
              <Content style={{ display: "flex", justifyContent: "center" }}>
                <Spin size="large" />
              </Content>
            ) : kycStatus === "ADD_INFO_PENDING" ? (
              "Please fill up KYC Form."
            ) : kycStatus === "REJECTED" ? (
              "Your KYC Form was Rejected. Please correct the form."
            ) : kycStatus === "BLOCKED" ? (
              "Your KYC is blocked by admin."
            ) : (
              "Please wait while your KYC form is being Verified."
            )}
          </Modal>
        )}
        {sendMoney ? (
          <SendMoney
            onSendMoney={(e: any) => setSendMoney(e)}
            exchangeRates={DashboardReducers?.ExchangeRates}
          />
        ) : (
          <>
            <Space className="flex-space-between">
              <Typography.Title
                level={4}
                style={{ color: `${retrivedColorCodes.primaryColor}` }}
              >
                Today's Exchange Rate
              </Typography.Title>
              <Button
                onClick={() => setShowAddRecipient(true)}
                className="add-recipient"
                type="primary"
              >
                <UserAddOutlined style={{ fontSize: 30 }} />
              </Button>
            </Space>
            <Divider />
            {DashboardReducers.loading ? (
              <Content className="flex-center">
                <Spin />
              </Content>
            ) : (
              <Content className="flex-center">
                <Space direction="vertical">
                  <Space style={{ margin: "0px 10px" }}>
                    <Image
                      src={AusFlag}
                      width={30}
                      preview={false}
                      style={{ marginLeft: -4 }}
                    ></Image>
                    <Input
                      value={DashboardReducers?.ExchangeRates?.fromAmount?.toFixed(
                        2
                      )}
                      suffix="AUD"
                      style={{ width: "100%" }}
                    />
                  </Space>
                  <Space>
                    <Image src={NepalFlag} preview={false} width={40}></Image>
                    <Input
                      value={DashboardReducers?.ExchangeRates?.toAmount?.toFixed(
                        2
                      )}
                      suffix="NPR"
                      style={{ width: "100%" }}
                    />
                  </Space>
                  <Content className="flex-center">
                    <Button
                      onClick={
                        kycStatus !== "VERIFIED" ? showModal : showSendMoney
                      }
                      type="primary"
                      style={{
                        backgroundColor: `${retrivedColorCodes.primaryColor}`,
                        color: `${retrivedColorCodes.blackColor}`,
                      }}
                    >
                      Send Money
                    </Button>
                  </Content>
                </Space>
              </Content>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default Dashboard;

import { ofType } from "redux-observable";
import { API } from "Redux/Apis";
import { flatMap, map } from "rxjs/operators";
import { GET_SEND_LOCATION_FAILURE, GET_SEND_LOCATION_REQUEST, GET_SEND_LOCATION_SUCCESS } from "./SendLocationActions";

export const SendLocationApi = () => API.get('/remittance/customer/get-list')

export const SendLocationEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(GET_SEND_LOCATION_REQUEST),
        flatMap(async () => {
            try {
                const response = await SendLocationApi();
                return { payload: response }
            } catch (e) {
                return { error: e }
            }
        }),
        map((action: any) => {
            if (action?.payload) {
                return {
                    type: GET_SEND_LOCATION_SUCCESS,
                    payload: action?.payload,
                };
            } else if (action.error) {
                return {
                    type: GET_SEND_LOCATION_FAILURE,
                    payload: action?.error?.response?.data
                };
            }
        })
    );

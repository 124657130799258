import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  notification,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import Mail from "Assets/mail.png";
import Mic from "Assets/mic.png";
import { TrademarkCircleOutlined, PhoneOutlined, YoutubeOutlined,MailOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { API } from "Redux/Apis";
import { useForm } from "antd/lib/form/Form";

var colorCode :any = window.localStorage.getItem('localColorCode');
const retrivedColorCodes = JSON.parse(colorCode)


const { Panel } = Collapse;
const customPanelStyle = {
  borderRadius: 4,
  marginBottom: 2,
  border: 0,
  background: "#ffff",
  overflow: "hidden",
  fontSize: "20px",
  color: "#1890ff",
};
interface HelpAndSupportProps {}

const HelpAndSupport: React.FC<HelpAndSupportProps> = () => {
  const [data, setData] = useState<any>();

  const [form] = useForm();

  const handleSubmit = async (values: any) => {
    try {
      const response = await API.post("/customer-query/customer/add", values);
      if (response.status === 200) {
        notification.success({ message: "Successfully Sent!" });
        form.resetFields();
      }
    } catch (error:any) {
      notification.error({ message: error?.response?.data?.message });
    }
  };
  useEffect(() => {
    const fetchSupportApi = async () => {
      try {
        const response = await API.get("/customer-query/get-support-info");
        setData(response.data);
      } catch (error:any) {
        notification.error({
          message: "Something went wrong! Please try again later.",
          description: error?.response?.data?.message,
        });
      }
    };
    fetchSupportApi();
  }, []);

  return (
    <Card>
      <Typography.Title level={3} style={{ color: `${retrivedColorCodes?.primaryColor}` }}>
        Help & Support
      </Typography.Title>
      <Divider />
      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32, xl: 32 }, 5]}>
        <Col xs={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
          <Card style={{ textAlign: "center" }}>
            <h4 style={{ color: "orange" }}>HAVING PROBLEM ?</h4>
            <h4>You are just click away from a solutions !</h4>
            <h4>We are at your service 24 hr 365 days !</h4>
          </Card>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <Card
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <a
              href={`mailto:${data && data.supportEmail}`}
              target='_blank'
              style={{ color: "black" }}
            >
              <MailOutlined style={{ fontSize: "50px", color:  `${retrivedColorCodes?.primaryColor}` }} />
              <h4>Write a mail </h4>
            </a>
          </Card>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <Card
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <a href={data && data.url} style={{ color: "black" }} target='_blank'>
              <YoutubeOutlined style={{ fontSize: "58px", color:  `${retrivedColorCodes?.primaryColor}` }} />
              <h4>Watch Tutorials</h4>
            </a>
          </Card>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <Card
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Collapse bordered={false}>
              <Panel
                showArrow={false}
                header={
                  <Space direction='vertical'>
                    <TrademarkCircleOutlined style={{ fontSize: "50px", color:  `${retrivedColorCodes?.primaryColor}` }} />
                    <Typography.Text>Report your problem</Typography.Text>
                  </Space>
                }
                key='1'
                style={customPanelStyle}
              >
                <Form form={form} onFinish={handleSubmit} className='login-form'>
                  <Form.Item
                    name='question'
                    label='Question'
                    rules={[{ required: true, message: "Please input your password!" }]}
                  >
                    <TextArea
                      rows={4}
                      //prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                      placeholder='Question?'
                    />
                  </Form.Item>
                  <Button htmlType='submit' type='primary' style={{ marginTop: "1rem" }}>
                    Send
                  </Button>
                </Form>
              </Panel>
            </Collapse>
          </Card>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
          <Card
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Collapse bordered={false}>
              <Panel
                showArrow={false}
                header={
                  <Space direction='vertical'>
                    <PhoneOutlined style={{ fontSize: "50px", color:  `${retrivedColorCodes?.primaryColor}` }} />
                    
                    <Typography.Text>Make a call</Typography.Text>
                  </Space>
                }
                key='1'
                style={customPanelStyle}
              >
                <a style={{ display: "flex" }}>
                  <p>Contact</p> :<p style={{ marginLeft: "1rem" }}>{data && data.contactNumber}</p>
                </a>
              </Panel>
            </Collapse>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default HelpAndSupport;

import {
  Button,
  Card,
  Form,
  Image,
  Input,
  Modal,
  notification,
  Select,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import hamroPay from "Assets/logo.png";
import { Content } from "antd/lib/layout/layout";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { PostSignupRequest } from "./SignupRedux/SignupActions";
import { API } from "Redux/Apis";

interface SignupProps {
  history?: any;
}

var colorCode: any = window.localStorage.getItem("localColorCode");
const retrivedColorCodes = JSON.parse(colorCode);

const Signup: React.FC<SignupProps> = ({ history }) => {
  const [showOPTVerificationModal, setShowOTPVerificationModal] =
    useState<boolean>(false);
  const [activeSignupType, setActiveSignupType] = useState<string>("email");
  const [otpCode, setOtpCode] = useState<any>();
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const dispatch = useDispatch();
  const SignupReducers = useSelector(
    (state: RootStateOrAny) => state.SignupReducers
  );

  const handleSignup = (values: any) => {
    values.agentCode = process.env.REACT_APP_API_AGENTCODE_FAKE as string;
    dispatch(PostSignupRequest(values));
    setPassword(values.password);
    setEmail(values.email);
    setPhone(values.mobileNo);
    setShowOTPVerificationModal(true);
  };

  const handleCancel = () => {
    setShowOTPVerificationModal(false);
  };
  const handleOk = async () => {
    //setShowOTPVerificationModal(false);
    if (activeSignupType === "email") {
      try {
        const res = await API.post("/customer/register/verify-email-otp", {
          id: SignupReducers.Signup.id,
          otp: otpCode,
          password: password,
          agentCode: process.env.REACT_APP_API_AGENTCODE_FAKE as string,
        });
        if (res.status === 200) {
          notification.success({ message: "Successfully verified!" });
          setShowOTPVerificationModal(false);
          history.push("/auth");
        }
      } catch (error: any) {
        notification.error({
          message: "Something went wrong. Please try again later.",
          description: error?.response?.data?.message,
        });
      }
    } else {
      try {
        const res = await API.post("/customer/verify-phone-otp", {
          id: SignupReducers.Signup.id,
          otp: otpCode,
          password: password,
          mobileNumber: phone,
          agentCode: process.env.REACT_APP_API_AGENTCODE_FAKE as string,
        });
        setShowOTPVerificationModal(false);
        if (res.status === 200) {
          notification.success({ message: "Successfully verified!" });
          history.push("/auth");
        } else {
          notification.error({
            message: "Something went wrong. Please try again later.",
          });
        }
      } catch (error: any) {
        // console.log(error);
      }
    }
  };
  const handleResendEmailOTP = async () => {
    try {
      const response = await API.post("/customer/resend-otp-email", {
        email: email,
        resendOtpFor: "REGISTRATION",
        agentCode: process.env.REACT_APP_API_AGENTCODE_FAKE as string,
      });
      if (response.status === 200) {
        notification.success({
          message: "OTP Resent!",
        });
      }
    } catch (error: any) {
      notification.error({
        message: "Something went wrong!",
        description: error.response.data.message,
      });
    }
  };
  const handleResendPhoneOTP = async () => {
    try {
      const response = await API.post("/customer/resend-otp-phone", {
        mobileNo: phone,
        agentCode: process.env.REACT_APP_API_AGENTCODE_FAKE as string,
      });
      if (response.status === 200) {
        notification.success({
          message: "OTP Resent!",
        });
      }
    } catch (error: any) {
      notification.error({
        message: "Something went wrong!",
        description: error.response.data.message,
      });
    }
  };

  const LoggedIn = useSelector(
    (state: RootStateOrAny) => state.LoginReducers.Login
  );
  useEffect(() => {
    if (LoggedIn) {
      history.push("/");
    }
  }, [LoggedIn, history]);
  return (
    <Card style={{ width: 360, height: "auto" }}>
      {SignupReducers.Signup !== false && (
        <Modal
          title="Please Enter you OTP Code."
          visible={showOPTVerificationModal}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Input
            type="number"
            placeholder="Enter 4 digits OTP Code "
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
          <Button
            style={{ marginTop: 10 }}
            onClick={
              activeSignupType === "email"
                ? handleResendEmailOTP
                : handleResendPhoneOTP
            }
          >
            Resend OTP Code
          </Button>
        </Modal>
      )}
      <Image src={retrivedColorCodes?.logo} preview={false} />
      <Form
        onFinish={handleSignup}
        layout="vertical"
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        {/* <Form.Item>
          <Content className="flex-space-between">
            <Button
              onClick={() => setActiveSignupType("email")}
              type={activeSignupType === "email" ? "primary" : "default"}
            >
              Signup with Email
            </Button>
            <Button
              onClick={() => setActiveSignupType("phone")}
              type={activeSignupType === "phone" ? "primary" : "default"}
            >
              Signup with Phone
            </Button>
          </Content>
        </Form.Item> */}
        <h3 style={{ textAlign: "center" }}>Sign up with Email</h3>
        {activeSignupType === "email" && (
          <Form.Item
            style={{ margin: 0 }}
            name="email"
            label="Email"
            rules={[
              { type: "email", message: "Please input valid Email." },
              { required: true, message: "Please input your Email!" },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
        )}
        {activeSignupType === "phone" && (
          <Content className="flex-space-between">
            <Form.Item
              style={{ margin: 0 }}
              name="callingCode"
              label="Code"
              rules={[
                { required: true, message: "Please Choose Country Code!" },
              ]}
            >
              <Select style={{ width: 70 }}>
                <Select.Option value="+977">+977</Select.Option>
                <Select.Option value="+61">+61</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="mobileNo"
              label="Phone"
              rules={[
                { min: 9, message: "Min length is 9." },
                { max: 15, message: "Max length is 15." },
                { required: true, message: "Please Enter Valid Phone number!" },
              ]}
            >
              <Input min={0} type="tel" style={{ width: "100%" }} />
            </Form.Item>
          </Content>
        )}
        <Form.Item
          style={{ margin: 0 }}
          name="password"
          label="Password"
          rules={[
            {
              pattern:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              message:
                "Your password must include at least 1 number, 1 uppercase, 1 lowercase and 1 symbol.",
            },
            { min: 8, message: "Min password length is 8." },
            { max: 15, message: "Max password length is 15." },
            { required: true, message: "Please input your Password!" },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item
          style={{ margin: 0 }}
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" style={{ width: "100%" }}>
            Signup
          </Button>
        </Form.Item>
        <Form.Item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Typography.Text>
            Already have an account? <Link to="/auth">Login.</Link>
          </Typography.Text>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Signup;

import { Alert, Button, Modal } from "antd";
import ProtectedLayout from "Layouts/ProtectedLayout";
import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Route, RouteChildrenProps, Switch } from "react-router";
import { TriggerLogout } from "Redux/LogoutRedux/LogoutAction";
import Activities from "Views/Activities/Activities";
import EachActivities from "Views/Activities/EachActivities/EachActivities";
import ChangePassword from "Views/ChangePassword/ChangePassword";
import Dashboard from "Views/Dashboard/Dashboard";
import Error from "Views/Error/Error";
import Faq from "Views/Faq/Faq";
import HelpAndSupport from "Views/HelpAndSupport/HelpAndSupport";
import KYC from "Views/KYC/KYC";
import LinkedBankAccounts from "Views/LinkedBankAccounts/LinkedBankAccounts";
import PrivacyPolicy from "Views/PrivacyPolicy/PrivacyPolicy";
import Profile from "Views/Profile/Profile";
import Referrals from "Views/Referrals/Referrals";
import SavedRecipients from "Views/SavedRecipients/SavedRecipients";
import Success from "Views/Success/Success";
import TermsAndConditions from "Views/TermsAndConditions/TermsAndConditions";
import RouteGuard from "./RouteGuard";

const ProtectedRoute = (props: RouteChildrenProps) => {
  const dispatch = useDispatch();
  const [finishedSession, setFinishedSession] = useState<boolean>(false);
  const ExpiryTime = useSelector(
    (state: RootStateOrAny) => state.LoginReducers.expiryTime
  );

  useEffect(() => {
    setInterval(() => {
      if (new Date().getTime() > ExpiryTime) {
        setFinishedSession(true);
      }
    }, 60 * 1000);
  }, [ExpiryTime]);

  const handleLogout = () => {
    dispatch(TriggerLogout());
    setFinishedSession(false);
  };
  return (
    <RouteGuard {...props}>
      <ProtectedLayout>
        <Modal
          visible={finishedSession}
          title="Session Expired"
          footer={<Button onClick={handleLogout}>OK</Button>}
          closable={false}
        >
          <Alert
            message="You will be redirected to Login Page"
            type="warning"
          />
        </Modal>
        <Switch>
          <Route
            exact
            path={`${props.match?.path}`}
            component={Dashboard}
          ></Route>
          <Route
            exact
            path={`${props.match?.path}activities`}
            component={Activities}
          ></Route>
          <Route
            exact
            path={`${props.match?.path}activities/:id`}
            component={EachActivities}
          ></Route>
          <Route
            exact
            path={`${props.match?.path}linked-bank-accounts`}
            component={LinkedBankAccounts}
          ></Route>
          <Route
            exact
            path={`${props.match?.path}saved-recipients`}
            component={SavedRecipients}
          ></Route>
          <Route
            exact
            path={`${props.match?.path}my-profile`}
            component={Profile}
          ></Route>
          <Route
            exact
            path={`${props.match?.path}referrals`}
            component={Referrals}
          ></Route>
          <Route
            exact
            path={`${props.match?.path}change-password`}
            component={ChangePassword}
          ></Route>
          <Route
            exact
            path={`${props.match?.path}privacypolicy`}
            component={PrivacyPolicy}
          ></Route>
          <Route
            exact
            path={`${props.match?.path}termsandconditions`}
            component={TermsAndConditions}
          ></Route>
          <Route
            exact
            path={`${props.match?.path}help`}
            component={HelpAndSupport}
          ></Route>
          <Route exact path={`${props.match?.path}faq`} component={Faq}></Route>
          <Route exact path={`${props.match?.path}KYC`} component={KYC}></Route>
          <Route
            exact
            path={`${props.match?.path}success`}
            component={Success}
          ></Route>
          <Route
            exact
            path={`${props.match?.path}error`}
            component={Error}
          ></Route>
        </Switch>
      </ProtectedLayout>
    </RouteGuard>
  );
};
export default ProtectedRoute;

import { Reducer } from "redux";
import { SENDER_INFORMATION } from "./SenderInformationActions";

const INIT_STATE = {
  SenderInformation: false,
  loading: false,
  error: false,
};

export const SenderInformationReducers: Reducer<any, any> = (
  state = INIT_STATE,
  action: any
) => {
  switch (action.type) {
    case SENDER_INFORMATION: {
      // console.log(action.payload)
      return {
        ...state,
        loading: false,
        error: false,
        SenderInformation: action?.payload,
      };
    }
    default:
      return state;
  }
};

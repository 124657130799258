import { Reducer } from "redux"
import { GET_SEND_LOCATION_FAILURE, GET_SEND_LOCATION_REQUEST, GET_SEND_LOCATION_SUCCESS } from "./SendLocationActions"

const INIT_STATE = {
    SendLocation: false,
    loading: false,
    error: false
}

export const SendLocationReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_SEND_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_SEND_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                SendLocation: action?.payload?.data
            }
        case GET_SEND_LOCATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload?.message
            }
        default:
            return state
    }
}
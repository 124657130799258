import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
import { API } from "Redux/Apis";
import laxmipay from "Assets/logo.png";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";

interface ForgotPasswordProps {
  history?: any;
}

function getFaviconEl() {
  return document.getElementById("favIcon");
}
function getFavTitleEl() {
  return document.getElementById("favTitle");
}

var colorCode: any = window.localStorage.getItem("localColorCode");
const retrivedColorCodes = JSON.parse(colorCode);

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ history }) => {
  const [defaultMethod, setDefaultMethod] = useState<string>("email");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [mobileNumber, setMobileNumber] = useState<number>();
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (retrivedColorCodes !== null) {
      const favicon: any = getFaviconEl(); // Accessing favicon element
      favicon.href =
        retrivedColorCodes !== null ? retrivedColorCodes?.titleBarIcon : "";
      const favTitle: any = getFavTitleEl(); // Accessing favicon element
      favTitle.innerHTML =
        retrivedColorCodes !== null ? retrivedColorCodes?.titleBarText : "";
    }
  }, [retrivedColorCodes]);

  const handleFinish = (values: any) => {
    values.agentCode = process.env.REACT_APP_API_AGENTCODE_FAKE;
    setTimeout(async () => {
      try {
        if (defaultMethod === "email") {
          const response = await API.post("/customer/resend-otp-email", {
            email: values.email || email,
            resendOtpFor: "VALIDATING_EMAIL",
            agentCode: process.env.REACT_APP_API_AGENTCODE_FAKE,
          });
          if (response.status === 200) {
            setShowModal(true);
            setEmail(response.data.userName);
            notification.success({
              message: `OTP sent. Please check your email.`,
            });
          }
        } else {
          const response = await API.post("/customer/resend-otp-phone", {
            mobileNo: values.mobileNo || mobileNumber,
            agentCode: process.env.REACT_APP_API_AGENTCODE_FAKE,
          });
          if (response.status === 200) {
            setMobileNumber(response.data.userName);

            setShowModal(true);
            notification.success({ message: "OTP sent!" });
          }
        }
      } catch (error: any) {
        notification.error({
          message: "SENDING OTP FAILED!",
          description: error.response.data?.message,
        });
      }
    }, 2000);
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const handleSubmitOtp = async (values: any) => {
    values.agentCode = process.env.REACT_APP_API_AGENTCODE_FAKE;
    try {
      if (defaultMethod === "email") {
        const response = await API.post("/customer/verify-email-otp", {
          email: email,
          otp: values.otp,
          agentCode: process.env.REACT_APP_API_AGENTCODE_FAKE,
        });
        if (response.status === 200) {
          const res = await axios.post(
            "https://laxmipay.ausnepit.me:8444/remittance/v1/api/customer/forget-password/change-password",
            values,
            {
              headers: {
                Authorization: `Bearer ${response.data.token}`,
              },
            }
          );
          if (res.status === 200) {
            // const res = await axios.post(
            //   "https://laxmipay.ausnepit.me:8443/remittance/v1/api/customer/forget-password/change-password",
            //   values,
            //   {
            //     headers: {
            //       Authorization: `Bearer ${response.data.token}`,
            //     },
            //   }
            // );
            if (res.status === 200) {
              notification.success({ message: "Successfully Reset!" });
              history.push("/auth");
            }
          }
        }
      } else {
        const response = await API.post(
          "/customer/forget-password/verify-otp",
          {
            mobileNumber: mobileNumber,
            otp: values.otp,
            agentCode: process.env.REACT_APP_API_AGENTCODE_FAKE,
          }
        );
        if (response.status === 200) {
          const res = await axios.post(
            "https://laxmipay.ausnepit.me:8444/remittance/v1/api/customer/forget-password/change-password",
            values,
            {
              headers: {
                Authorization: `Bearer ${response.data.token}`,
              },
            }
          );
          if (res.status === 200) {
            notification.success({ message: "Successfully Reset!" });
            history.push("/auth");
          }
        }
      }
    } catch (error: any) {
      notification.error({
        message: "Reset Password FAILED!",
        description: error.response.data?.message,
      });
    }
  };
  return (
    <Card style={{ width: 360, height: "auto" }}>
      <Image src={retrivedColorCodes?.logo} preview={false} />
      <Modal
        title="Reset Password"
        visible={showModal}
        footer={false}
        onCancel={onCancel}
      >
        <Form layout="vertical" onFinish={handleSubmitOtp}>
          <Form.Item>
            <Typography.Text>
              Check your {defaultMethod}. We've sent you the OTP code at{" "}
              {defaultMethod === "email" ? email : mobileNumber}.
            </Typography.Text>
          </Form.Item>
          <Form.Item
            name="otp"
            label="Enter OTP"
            rules={[{ required: true, message: "Please enter correct OTP." }]}
          >
            <Input />
          </Form.Item>
          <Form.Item style={{ marginTop: 10 }}>
            Didn't receive OTP in email?{" "}
            <Button onClick={handleFinish} type="primary">
              Resend OTP
            </Button>
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                message:
                  "Your password must include at least 1 number, 1 uppercase, 1 lowercase and 1 symbol.",
              },
              { min: 8, message: "Min password length is 8." },
              { max: 15, message: "Max password length is 15." },
              { required: true, message: "Please input your Password!" },
            ]}
            hasFeedback
          >
            <Input.Password type="password" />
          </Form.Item>
          <Form.Item
            name="conformPassword"
            label="Confirm New Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password type="password" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary" style={{ width: "100%" }}>
              Reset
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Button
        onClick={() => setDefaultMethod("email")}
        type={defaultMethod === "email" ? "primary" : "default"}
        style={{ width: "100%", backgroundColor: "#dc992b", color: "white" }}
      >
        Reset With Email
      </Button>
      <Button
        onClick={() => setDefaultMethod("phone")}
        type={defaultMethod === "phone" ? "primary" : "default"}
        style={{
          marginTop: 20,
          width: "100%",
          backgroundColor: "#dc992b",
          color: "white",
        }}
      >
        Reset With Phone
      </Button>
      <Row style={{ marginTop: 20 }}>
        <Col span={24}>
          We need your registered{" "}
          {defaultMethod === "email" ? "Email" : "Phone Number"} to send you
          password reset code.
        </Col>
        <Col span={24}>
          <Form onFinish={handleFinish} layout="vertical">
            {defaultMethod === "email" ? (
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please complete the fields" },
                ]}
              >
                <Input type="email" />
              </Form.Item>
            ) : (
              <Content className="flex-space-between">
                <Form.Item
                  name="callingCode"
                  label="Code"
                  rules={[
                    { required: true, message: "Please Choose Country Code!" },
                  ]}
                >
                  <Select style={{ width: 70 }}>
                    <Select.Option value="+977">+977</Select.Option>
                    <Select.Option value="+61">+61</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="mobileNo"
                  label="Phone Number"
                  rules={[
                    { min: 9, message: "Min length is 9." },
                    { max: 15, message: "Max length is 15." },
                    {
                      required: true,
                      message: "Please Enter Valid Phone number!",
                    },
                  ]}
                >
                  <Input min={0} type="tel" style={{ width: "100%" }} />
                </Form.Item>
              </Content>
            )}

            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  width: "100%",
                  backgroundColor: "#D31413",
                  color: "white",
                }}
              >
                Submit
              </Button>
            </Form.Item>
            <Space className="flex-space-between">
              <Typography.Text
                className="link-to"
                onClick={() => history.push("/auth")}
              >
                Login
              </Typography.Text>
              <Typography.Text
                className="link-to"
                onClick={() => history.push("/auth/signup")}
              >
                Signup
              </Typography.Text>
            </Space>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

export default ForgotPassword;

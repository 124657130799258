import { ofType } from "redux-observable";
import { API } from "Redux/Apis";
import { POST_POLIPAY_FAILURE, POST_POLIPAY_REQUEST, POST_POLIPAY_SUCCESS } from "Redux/Constants";
import { flatMap, map } from "rxjs/operators";

export const PoliPayApi = (data: any) => API.post("/transfer/poli-pay/initiate", data)

export const PoliPayEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(POST_POLIPAY_REQUEST),
        flatMap(async (action: any) => {
            try {
                const response = await PoliPayApi(action.payload);
                return { payload: response }
            } catch (e) {
                return { error: e }
            }
        }),
        map((action: any) => {
            if (action?.payload) {
                return {
                    type: POST_POLIPAY_SUCCESS,
                    payload: action?.payload,
                };
            } else if (action.error) {
                return {
                    type: POST_POLIPAY_FAILURE,
                    payload: action?.error?.response?.data
                };
            }
        })
    );

import { Card, List } from "antd";
import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";

interface NotificationListProps {
  history: RouteComponentProps["history"];
}

const NotificationList: React.FC<NotificationListProps> = ({ history }) => {
  const NotificationList = useSelector((state: RootStateOrAny) => state.NotificationReducers);

  return (
    <Card >
      <List
        itemLayout='horizontal'
        dataSource={NotificationList?.Notifications?.data}
        header={`Notifications (${NotificationList?.Notifications?.totalData})`}
        loading={NotificationList?.loading}
        //style={{ overflow: "hidden" }}
        className='p-break'
        renderItem={(item: any) => (
          <List.Item style={{ width: 200 }}>
            {item?.body === "Your KYC information could not be verified" ? (
              <List.Item.Meta
                style={{ marginTop: -10 }}
                title={<Link to={"/kyc"}>{item?.body}</Link>}
                description={<p className='p-break'>{item?.message}</p>}
              />
            ) : (
              <List.Item.Meta
                style={{ marginTop: -10 }}
                title={item?.body}
                description={item?.message}
              />
            )}
          </List.Item>
        )}
      />
    </Card>
  );
};

export default NotificationList;

import { ofType } from "redux-observable";
import { API } from "Redux/Apis";
import { GET_NOTIFICATION_FAILURE, GET_NOTIFICATION_REQUEST, GET_NOTIFICATION_SUCCESS, } from "Redux/Constants";
import { flatMap, map } from "rxjs/operators";



export const NotificationApi = () => API.get('/customer/notification/get-all?page=1&pageSize=10&sort=descending&sortParameter=createdOn')
export const NotificationViewedApi = () => API.get("/customer/notification/viewed")

export const NotificationEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(GET_NOTIFICATION_REQUEST),
        flatMap(async () => {
            try {
                const response = await NotificationApi();
                return { payload: response }
            } catch (e) {
                return { error: e }
            }
        }),
        map((action: any) => {
            if (action.payload) {
                return {
                    type: GET_NOTIFICATION_SUCCESS,
                    payload: action?.payload,
                };
            } else if (action.error) {
                return {
                    type: GET_NOTIFICATION_FAILURE,
                    payload: action?.error?.response?.data
                };
            }
        })
    );

import { combineEpics } from "redux-observable";
import { ActivitiesEpic } from "Views/Activities/ActivitiesEpic";
import { LoginEpic } from "Views/Auth/LoginRedux/LoginEpics";
import { SignupEpic } from "Views/Auth/SignupRedux/SignupEpics";
import { DashboardEpic } from "Views/Dashboard/DashboardEpic";
import { PrivacyPolicyEpic } from "Views/PrivacyPolicy/PrivacyPolicyEpic";
import { NotificationEpic } from "./NotificationRedux/NotificationEpic";
import { TermsAndConditionsEpic } from 'Views/TermsAndConditions/TermsAndConditionsEpic';
import { FaqEpic } from "Views/Faq/FaqEpic";
import { BankListEpic } from "Views/Dashboard/BankList/BankListEpic";
import { PoliPayEpic } from "Views/Dashboard/SendMoney/PoliPayRedux/PoliPayEpic";
import { LinkedBanksAccountsEpic } from "Views/LinkedBankAccounts/LinkedBankAccountsEpic";
import { SavedRecipientsEpic } from "Views/SavedRecipients/SavedRecipientsEpic";
import { SendLocationEpic } from "Views/Dashboard/SendLocationRedux/SendLocationEpic";
import { SupportInfoEpic } from "Views/Referrals/ReferralsEpic";
import { ProfileEpic } from "Views/KYC/ProfileEpic";
import { KycRejectionEpic } from "Views/KYC/KycRejectionRedux/KycEpic";

export const RootEpic = combineEpics(
    TermsAndConditionsEpic,
    SignupEpic,
    LoginEpic,
    NotificationEpic,
    DashboardEpic,
    ActivitiesEpic,
    PrivacyPolicyEpic,
    FaqEpic,
    BankListEpic,
    PoliPayEpic,
    LinkedBanksAccountsEpic,
    SavedRecipientsEpic,
    SendLocationEpic,
    SupportInfoEpic,
    ProfileEpic,
    KycRejectionEpic
);

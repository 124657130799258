import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Select,
  Tabs,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { API } from "Redux/Apis";
import { GetSavedRecipientsRequest } from "./SavedRecipientsActions";
import { GetBankListRequest } from "Views/Dashboard/BankList/BankListActions";

interface SavedRecipientsProps {}
var colorCode: any = window.localStorage.getItem("localColorCode");
const retrivedColorCodes = JSON.parse(colorCode);
const SavedRecipients: React.FC<SavedRecipientsProps> = () => {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [recipientId, setRecipientId] = useState<number>(0);
  const [showAddRecipient, setShowAddRecipient] = useState<boolean>(false);

  const [bankId, setBankId] = useState<number>();

  const dispatch = useDispatch();

  const fetchBankList = () => {
    dispatch(GetBankListRequest());
  };

  const BankLists = useSelector(
    (state: RootStateOrAny) =>
      state.BankListReducers.BankLists.associatedBankList
  );

  const SavedRecipientsReducers = useSelector(
    (state: RootStateOrAny) => state.SavedRecipientsReducers
  );

  const handleRecipientDetails = async (values: any) => {
    const valuesToSend = {
      ...values,
    };
    try {
      const response = await API.post("/recipient/add", valuesToSend);

      if (response.status === 200) {
        dispatch(GetSavedRecipientsRequest());
        notification.success({ message: "Add Recipient Details Success!" });
        setShowAddRecipient(false);
        form1.resetFields();
      }
    } catch (error: any) {
      notification.error({
        message: "Something went wrong!",
        description: error?.response?.data?.message,
      });
    }
  };

  const onCancel = () => {
    setShowAddRecipient(false);
  };

  const handleBankDetails = async (values: any) => {
    const bank = BankLists?.filter((bank: any) => bank.id == bankId);
    const valuesToSend = {
      ...values,
      bankId: bankId,
      locationId: bank[0]?.locationId,
    };
    try {
      const response = await API.post(
        "/customer/link-bank-account",
        valuesToSend
      );
      if (response.status === 200) {
        notification.success({ message: "Add Bank Details Success!" });
        setShowAddRecipient(false);
        form.resetFields();
      }
    } catch (error: any) {
      notification.error({
        message: "Something went wrong!",
        description: error?.response?.data?.message,
      });
    }
  };

  useEffect(() => {
    if (SavedRecipientsReducers.SavedRecipients === false) {
      dispatch(GetSavedRecipientsRequest());
    }
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "recipientFirstName",
      key: "name",
      record: (text: string, record: any) => {
        return <Typography.Text>{record.recipientFirstName}</Typography.Text>;
      },
    },
    {
      title: "City",
      dataIndex: "recipientCity",
      key: "recipientcity",
      responsive: ["md"] as Breakpoint[],
    },
    {
      title: "Province",
      dataIndex: "recipientZone",
      key: "recipientZone",
      responsive: ["lg"] as Breakpoint[],
    },
    {
      title: "Mobile Number",
      dataIndex: "recipientMobileNumber",
      key: "recipientMobileNumber",
      responsive: ["md"] as Breakpoint[],
    },
    {
      title: "Email Address",
      dataIndex: "recipientEmail",
      key: "recipientEmail",
      width: 200,
      ellipsis: true,
      responsive: ["sm"] as Breakpoint[],
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => {
        return (
          <Space size="small">
            <Tooltip placement="top" title="Edit Saved Recipient">
              <Button
                type="primary"
                onClick={() => {
                  setShowEdit(true);
                  setRecipientId(record.recipientId);
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Popconfirm
              title="Are you sure to delete this Recipient?"
              onConfirm={async () => {
                try {
                  const response = await API.get(
                    `/recipient/delete?recipientId=${record.recipientId}`
                  );
                  if (response.status === 200) {
                    notification.success({ message: "Successfully Deleted!" });
                    dispatch(GetSavedRecipientsRequest());
                  }
                } catch (error: any) {
                  notification.error({
                    message: error?.response?.data?.message,
                  });
                }
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip placement="top" title="Edit Saved Recipient">
                <Button type="primary">
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  const SaveForm = async (values: any) => {
    const valuesToSend = {
      ...values,
      recipientId: recipientId,
      addressLineOne: values.recipientAddressLine1,
    };
    try {
      const response = await API.post("/recipient/update", valuesToSend);
      if (response.status === 200) {
        notification.success({ message: "Successfully Saved!" });
        dispatch(GetSavedRecipientsRequest());
      }
    } catch (error: any) {
      notification.error({
        message: "Something Went Wrong!",
        description: error?.response?.data?.message,
      });
    }
  };
  useEffect(() => {
    const details = SavedRecipientsReducers?.SavedRecipients?.data?.filter(
      (d: any) => d.recipientId === recipientId
    );
    form.setFieldsValue(details && details[0]);
  }, [showEdit, recipientId]);

  const [form] = useForm();
  const [form1] = useForm();
  return (
    <Card>
      <Modal visible={showAddRecipient} footer={false} onCancel={onCancel}>
        <Tabs defaultActiveKey="recipientDetails">
          <Tabs.TabPane tab="Recipient Details" key="recipientDetails">
            <Form
              form={form1}
              onFinish={handleRecipientDetails}
              layout="vertical"
            >
              <Form.Item
                label="First Name"
                name="recipientFirstName"
                rules={[{ required: true, message: "First Name is required!" }]}
              >
                <Input size="large" placeholder="First Name" />
              </Form.Item>
              <Form.Item label="Middle Name" name="recipientMiddleName">
                <Input size="large" placeholder="Middle Name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="recipientLastName"
                rules={[{ required: true, message: "Last Name is required!" }]}
              >
                <Input size="large" placeholder="Last Name" />
              </Form.Item>
              <Typography.Text>
                Please ensure your recipient's name entered here matches the
                details on their government issued ID as they will need to
                present it during collections. Any incorrect names cause a delay
                when collecting funds.
              </Typography.Text>
              <Form.Item
                label="Address Line 1"
                name="addressLineOne"
                rules={[
                  { required: true, message: "Address Line 1 is required!" },
                ]}
              >
                <Input size="large" placeholder="Address Line 1" />
              </Form.Item>
              <Form.Item
                label="City"
                name="recipientCity"
                rules={[{ required: true, message: "City is required!" }]}
              >
                <Input size="large" placeholder="City" />
              </Form.Item>
              <Form.Item
                label="Select Province"
                name="recipientZone"
                rules={[{ required: true, message: "Province is required!" }]}
              >
                <Select placeholder="Select Province">
                  <Select.Option value={1}>Province 1</Select.Option>
                  <Select.Option value={2}>Province 2</Select.Option>
                  <Select.Option value={3}>Province 3</Select.Option>
                  <Select.Option value={4}>Province 4</Select.Option>
                  <Select.Option value={5}>Province 5</Select.Option>
                  <Select.Option value={6}>Province 6</Select.Option>
                  <Select.Option value={7}>Province 7</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                name="recipientMobileNumber"
                rules={[
                  { min: 9, message: "Min length is 9." },
                  { max: 15, message: "Max length is 15." },
                  { required: true, message: "Mobile Number is required!" },
                ]}
              >
                <Input type="number" size="large" placeholder="Mobile Number" />
              </Form.Item>
              <Form.Item label="Email Address" name="recipientEmail">
                <Input type="email" size="large" placeholder="Email Address" />
              </Form.Item>
              <Form.Item
                label="Reason For Sending"
                name="reasonForSending"
                rules={[
                  {
                    required: true,
                    message: "Reason For Sending is required!",
                  },
                ]}
              >
                {/* <Input size="large" placeholder="Reason For Sending" /> */}
                <Select placeholder="Select Reason for Sending">
                  <Select.Option value="personal">Personal</Select.Option>
                  <Select.Option value="family">Family</Select.Option>
                  <Select.Option value="business">Business</Select.Option>
                  <Select.Option value="others">Others</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  style={{
                    backgroundColor: `${retrivedColorCodes?.primaryColor}`,
                    color: `${retrivedColorCodes?.blackColor}`,
                  }}
                >
                  Submit Recipients Details
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Bank Details" key="bankDetails">
            <Form form={form} onFinish={handleBankDetails} layout="vertical">
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true, message: "First Name is required!" }]}
              >
                <Input size="large" placeholder="First Name" />
              </Form.Item>
              <Form.Item label="Middle Name" name="middleName">
                <Input size="large" placeholder="Middle Name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[{ required: true, message: "Last Name is required!" }]}
              >
                <Input size="large" placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                label="Address Line 1"
                name="addressLineOne"
                rules={[
                  { required: true, message: "Address Line 1 is required!" },
                ]}
              >
                <Input size="large" placeholder="Address Line 1" />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[{ required: true, message: "City is required!" }]}
              >
                <Input size="large" placeholder="City" />
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                name="mobileNumber"
                rules={[
                  { min: 9, message: "Min length is 9." },
                  { max: 15, message: "Max length is 15." },
                  { required: true, message: "Mobile Number is required!" },
                ]}
              >
                <Input
                  min={0}
                  type="number"
                  size="large"
                  placeholder="Mobile Number"
                />
              </Form.Item>
              <Form.Item label="Email Address" name="emailAddress">
                <Input size="large" placeholder="Email Address" />
              </Form.Item>
              <Form.Item
                label="Select Province"
                name="zone"
                // rules={[
                //   { required: true, message: "Select Province is required!" },
                // ]}
              >
                <Select placeholder="Select Province">
                  <Select.Option value={1}>Province 1</Select.Option>
                  <Select.Option value={2}>Province 2</Select.Option>
                  <Select.Option value={3}>Province 3</Select.Option>
                  <Select.Option value={4}>Province 4</Select.Option>
                  <Select.Option value={5}>Province 5</Select.Option>
                  <Select.Option value={6}>Province 6</Select.Option>
                  <Select.Option value={7}>Province 7</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Account Holder's Name"
                name="accountHolderName"
                rules={[
                  {
                    required: true,
                    message: "Account Holder's Name is required!",
                  },
                ]}
              >
                <Input size="large" placeholder="Account Holder's Name" />
              </Form.Item>
              <Form.Item
                label="Account Number"
                name="accountNumber"
                rules={[
                  { required: true, message: "Account Number is required!" },
                ]}
              >
                <Input size="large" placeholder="Account Number" />
              </Form.Item>
              <Form.Item
                label="Select Bank"
                name="bank"
                rules={[{ required: true, message: "Bank is required!" }]}
              >
                <Select
                  onSelect={(value: any, key: any) => {
                    setBankId(key.key);
                  }}
                  placeholder="Select Bank"
                >
                  {BankLists?.map((bank: any) => {
                    return (
                      <Select.Option key={bank.id} value={bank.bankName}>
                        {bank.bankName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Branch"
                name="branch"
                // rules={[{ required: true, message: "branch is required!" }]}
              >
                <Input size="large" placeholder="Branch Name" />
              </Form.Item>
              <Form.Item
                label="Reason For Sending"
                name="reasonForSending"
                rules={[
                  {
                    required: true,
                    message: "Reason For Sending is required!",
                  },
                ]}
              >
                <Select placeholder="Select Reason for Sending">
                  <Select.Option value="personal">Personal</Select.Option>
                  <Select.Option value="family">Family</Select.Option>
                  <Select.Option value="business">Business</Select.Option>
                  <Select.Option value="others">Others</Select.Option>
                </Select>
                {/* <Input size="large" placeholder="Reason for Sending" /> */}
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  style={{
                    backgroundColor: `${retrivedColorCodes?.primaryColor}`,
                    color: `${retrivedColorCodes?.blackColor}`,
                  }}
                >
                  Submit Bank Details
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
      <Modal
        visible={showEdit}
        footer={false}
        onCancel={() => setShowEdit(false)}
      >
        <Typography.Title
          level={4}
          style={{ color: `${retrivedColorCodes?.primaryColor}` }}
        >
          Edit Recipient Details
        </Typography.Title>
        <Form form={form} onFinish={SaveForm} layout="vertical">
          <Form.Item
            name="recipientFirstName"
            label="First Name"
            rules={[{ required: true, message: "First Name is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="recipientMiddleName" label="Middle Name">
            <Input />
          </Form.Item>
          <Form.Item
            name="recipientLastName"
            label="last Name"
            rules={[{ required: true, message: "Last Name is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="recipientAddressLine1"
            label="Address Line 1"
            rules={[{ required: true, message: "Address Line 1 is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="recipientCity"
            label="City"
            rules={[{ required: true, message: "City is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="recipientZone"
            label="Zone"
            rules={[{ required: true, message: "Zone is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="recipientMobileNumber"
            label="Mobile Number"
            rules={[
              { min: 9, message: "Min length is 9." },
              { max: 15, message: "Max length is 15." },
              { required: true, message: "Mobile Number is required!" },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item name="recipientEmail" label="Email Address">
            <Input type="email" />
          </Form.Item>
          <Form.Item
            name="reasonForSending"
            label="Reason For Sending"
            rules={[
              { required: true, message: "Reason For Sending is required!" },
            ]}
          >
            <Select placeholder="Select Reason for Sending">
              <Select.Option value="personal">Personal</Select.Option>
              <Select.Option value="family">Family</Select.Option>
              <Select.Option value="business">Business</Select.Option>
              <Select.Option value="others">Others</Select.Option>
            </Select>
            {/* <Input /> */}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography.Title
          level={4}
          style={{ color: `${retrivedColorCodes?.primaryColor}` }}
        >
          Saved Recipients
        </Typography.Title>
        <Typography.Title
          level={4}
          style={{
            color: `${retrivedColorCodes?.blackColor}`,
            backgroundColor: `${retrivedColorCodes?.primaryColor}`,
            padding: "4px 10px",
            borderRadius: "2px",
            fontSize: "16px",
            cursor: "pointer",
          }}
          onClick={() => {
            setShowAddRecipient(true);
            fetchBankList();
          }}
        >
          Add Recipient / Bank
        </Typography.Title>
      </div>

      <Table
        style={{ marginTop: 30 }}
        columns={columns}
        pagination={{ position: ["bottomLeft"] }}
        loading={SavedRecipientsReducers?.loading}
        dataSource={SavedRecipientsReducers?.SavedRecipients?.data}
      />
      <Divider />
    </Card>
  );
};

export default SavedRecipients;

import { Reducer } from "redux"
import { SENDER_DETAILS } from "./SenderDetailsActions"

const INIT_STATE = {
    SenderBankDetails: false,
    loading: false,
    error: false,
}
export const SenderDetailsReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case SENDER_DETAILS: {
            return {
                ...state,
                SenderBankDetails: action?.payload,
                loading: false,
                error: false
            }
        }
        default:
            return state
    }
}
import React from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import ProtectedRoute from "./ProtectedRoute";

const MainRoute: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/auth' component={AuthRoute} />
        <Route path='/' component={ProtectedRoute} />
      </Switch>
    </BrowserRouter>
  );
};
export default MainRoute;

import { ofType } from "redux-observable";
import { API } from "Redux/Apis";
import { flatMap, map } from "rxjs/operators";
import {
  GET_LINKED_BANKS_FAILURE,
  GET_LINKED_BANKS_REQUEST,
  GET_LINKED_BANKS_SUCCESS,
} from "./LinkedBankAccountsActions";

const LinkedBanksAccountsApi = (pageNo: number) =>
  //   API.get(`/customer/linked-bank-account/get?page=${pageNo}&pageSize=10`);
  API.get(`/customer/linked-bank-account/get?page=${pageNo}&pageSize=50`);

export const LinkedBanksAccountsEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(GET_LINKED_BANKS_REQUEST),
    flatMap(async (action: any) => {
      try {
        const response = await LinkedBanksAccountsApi(action.payload);
        return { payload: response };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action: any) => {
      if (action.payload) {
        return {
          type: GET_LINKED_BANKS_SUCCESS,
          payload: action?.payload,
        };
      } else if (action.error) {
        return {
          type: GET_LINKED_BANKS_FAILURE,
          payload: action?.error?.response?.data,
        };
      }
    })
  );

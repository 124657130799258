import { ofType } from "redux-observable";
import { API } from "Redux/Apis";
import { GET_EXCHANGERATES_FAILURE, GET_EXCHANGERATES_REQUEST, GET_EXCHANGERATES_SUCCESS } from "Redux/Constants";
import { flatMap, map } from "rxjs/operators";

const ExchangeRatesApi = () => API.get("/exchange-rate/user/get?from=AUD&to=NPR&fromAmount=1")
export const DashboardEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(GET_EXCHANGERATES_REQUEST),
        flatMap(async () => {
            try {
                const response = await ExchangeRatesApi();
                return { payload: response }

            } catch (e) {
                return { error: e }
            }
        }),
        map((action: any) => {
            if (action.payload) {
                return {
                    type: GET_EXCHANGERATES_SUCCESS,
                    payload: action?.payload,
                };
            } else if (action.error) {
                return {
                    type: GET_EXCHANGERATES_FAILURE,
                    payload: action?.error?.response?.data
                };
            }
        })
    );
import { Reducer } from "react"
import { BANK_DEPOSIT_ID, BANK_OR_BRANCH, POST_POLIPAY_FAILURE, POST_POLIPAY_REQUEST, POST_POLIPAY_SUCCESS, RECEIVING_AMOUNT, REMITTANCE_DEPOSIT_ID, TRANSACTION_AMOUNT, TRIGGER_LOGOUT } from "Redux/Constants"
import { DELIVERY_NOTIFICATION, DISCOUNT_USE_LOYALTY_POINTS, PAYMENT_METHOD, RESET_POLIPAY } from "./PoliPayActions"

const INIT_STATE = {
    PoliPayRes: false,
    loading: false,
    error: false,
    transactionAmount: false,
    bankOrBranch: false,
    receivingAmount: false,
    bankDepositId: false,
    remittanceDepositId: false,
    paymentMethod: false,
    notificationFlag: false,
    useLoyaltyPoints: false
}

export const PoliPayReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case POST_POLIPAY_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            }
        case POST_POLIPAY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                PoliPayRes: action?.payload?.data
            }
        case POST_POLIPAY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload?.message
            }
        case TRANSACTION_AMOUNT: {
            return {
                ...state,
                loading: false,
                error: false,
                transactionAmount: action?.payload
            }
        }
        case BANK_OR_BRANCH:
            return {
                ...state,
                loading: false,
                error: false,
                bankOrBranch: action?.payload
            }
        case RECEIVING_AMOUNT:
            return {
                ...state,
                loading: false,
                error: false,
                receivingAmount: action?.payload
            }
        case BANK_DEPOSIT_ID:
            return {
                ...state,
                loading: false,
                error: false,
                bankDepositId: action?.payload
            }
        case REMITTANCE_DEPOSIT_ID:
            return {
                ...state,
                loading: false,
                error: false,
                remittanceDepositId: action?.payload
            }
        case PAYMENT_METHOD:
            return {
                ...state,
                loading: false,
                error: false,
                paymentMethod: action?.payload
            }
        case TRIGGER_LOGOUT: {
            return {
                ...state,
                loading: false,
                error: false,
                PoliPayRes: false
            }
        }
        case RESET_POLIPAY:
            return {
                ...state,
                loading: false,
                error: false,
                PoliPayRes: false,
                paymentMethod: false,
                remittanceDepositId: false,
                bankDepositId: false,
                receivingAmount: false,
                bankOrBranch: false,
                transactionAmount: false
            }
        case DELIVERY_NOTIFICATION:
            return {
                ...state,
                loading: false,
                error: false,
                notificationFlag: action?.payload
            }
        case DISCOUNT_USE_LOYALTY_POINTS:
            
            return {
                ...state,
                loading: false,
                error: false,
                useLoyaltyPoints: action?.payload
            }
        default:
            return state
    }
}

import { ofType } from "redux-observable";
import { API } from "Redux/Apis";
import { flatMap, map } from "rxjs/operators";
import { GET_KYC_REJECTION_FAILURE, GET_KYC_REJECTION_REQUEST, GET_KYC_REJECTION_SUCCESS } from "./KycReductionAction";


const KycRejectionApi = () => API.get("/customer/get-kyc-rejection")
export const KycRejectionEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(GET_KYC_REJECTION_REQUEST),
        flatMap(async () => {
            try {
                const response = await KycRejectionApi();
                return { payload: response }
            } catch (e) {
                return { error: e }
            }
        }),
        map((action: any) => {
            if (action.payload) {
                return {
                    type: GET_KYC_REJECTION_SUCCESS,
                    payload: action?.payload,
                };
            } else if (action.error) {
                return {
                    type: GET_KYC_REJECTION_FAILURE,
                    payload: action?.error?.response?.data
                };
            }
        })
    );

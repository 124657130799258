import {
  CompassOutlined,
  EnvironmentOutlined,
  MailOutlined,
  MobileOutlined,
  NumberOutlined,
  SmileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  notification,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { API } from "Redux/Apis";
import { GetKycRejectionRequest } from "./KycRejectionRedux/KycReductionAction";
import { GetProfileRequest } from "./ProfileActions";

interface KYCProps {
  history: RouteComponentProps["history"];
}

const KYC: React.FC<KYCProps> = ({ history }) => {
  const [identificationType, setIdentificationType] = useState<any>(null);
  const [front, setFront] = useState<string>("");
  const [back, setBack] = useState<string>("");
  const [profileImage, setProfileImage] = useState<string>("");
  const dispatch = useDispatch();
  const [form] = useForm();

  const KycRejectionReducer = useSelector(
    (state: RootStateOrAny) => state.KycRejectionReducer
  );
  const handleChangefront = async (e: any) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const res = await API.post("/images/add/IDENTIFICATION_FRONT", formData);
      if (res.status === 200) {
        notification.success({ message: "Uploading ID front Success!" });
        setTimeout(() => {
          setFront(res.data.imagePath);
        }, 500);
      }
    } catch (error: any) {
      notification.error({
        message: "Uploading ID front FAILED!",
        description: error?.response?.data?.message,
      });
    }
  };

  const handleChangeback = async (e: any) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    try {
      const res = await API.post("/images/add/IDENTIFICATION_BACK", formData);
      if (res.status === 200) {
        notification.success({ message: "Uploading ID back Success!" });
        // setTimeout(() => {
        setBack(res.data.imagePath);
        // }, 500);
      }
    } catch (error: any) {
      notification.error({
        message: "Uploading ID back FAILED!",
        description: error?.response?.data?.message,
      });
    }
  };
  const handleChangeuser = async (e: any) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("image", e.target.files[0]);
    try {
      const res = await API.post("/images/add/PROFILE", formData);
      if (res.status === 200) {
        notification.success({ message: "Uploading User Image Success!" });
        setTimeout(() => {
          setProfileImage(res.data.imagePath);
        }, 500);
      }
    } catch (error: any) {
      notification.error({
        message: "Uploading User Image FAILED!",
        description: error?.response?.data?.message,
      });
    }
  };

  const handleSubmit = async (values: any) => {
    const valuesToSend = {
      ...values,
      phone: values.phoneNumber,
      dateOfBirth: values.dateOfBirth.format("DD-MM-YYYY"),
      issuedDate: values.issuedDate.format("DD-MM-YYYY"),
      identificationExpireDate:
        values?.identificationExpireDate?.format("DD-MM-YYYY"),
      identificationType,
    };
    try {
      const response = await API.post(
        "/customer/add-information",
        valuesToSend
      );
      if (response.status === 200) {
        notification.success({ message: "Successfully Submitted!" });
        history.push("/");
        localStorage.setItem("kycStatus", response.data.kycStatus);
        dispatch(GetProfileRequest());
      }
    } catch (error: any) {
      notification.error({
        message: "ERROR!",
        description: error?.response?.data?.message,
      });
    }
  };

  const kycStatus = useSelector(
    (state: RootStateOrAny) => state.ProfileReducers.ProfileDetails.kycStatus
  );

  useEffect(() => {
    API.get("/customer/get-profile")
      .then((res: any) => {
        form.setFieldsValue({
          email: res.data.email,
          phoneNumber: res.data.phoneNumber,
        });
      })
      .then((res) => {})
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (kycStatus === "REJECTED") {
      dispatch(GetKycRejectionRequest());
    }
  }, [kycStatus]);
  useEffect(() => {
    if (KycRejectionReducer.KycRejection !== false) {
      const UserData = KycRejectionReducer.KycRejection;
      form.setFieldsValue({
        ...UserData,
        firstName: UserData.customerFirstName,
        middleName: UserData.customerMiddleName,
        lastName: UserData.customerLastName,
        identificationNo: UserData.identificationNumber,
        issuedFrom: UserData.identificationIssuedFrom,
        dateOfBirth: moment(UserData.dateOfBirth, "DD-MM-YYYY"),
        issuedDate: moment(UserData.identificationIssuedOn, "DD-MM-YYYY"),
        identificationExpireDate:
          moment(UserData.identificationExpirationDate, "DD-MM-YYYY") || null,
      });
    }
  }, [kycStatus, KycRejectionReducer.KycRejection]);
  useEffect(() => {
    if (KycRejectionReducer.KycRejection !== false) {
      const UserData = KycRejectionReducer.KycRejection;
      if (UserData.isIdentificationFrontOk) {
        setFront(UserData.identificationFront);
      } else {
        setFront("");
      }
      if (UserData.isProfilePictureOk) {
        setProfileImage(UserData.profileImage);
      } else {
        setProfileImage("");
      }
      if (UserData.isIdentificationBackOk) {
        setBack(UserData.identificationBack);
      } else {
        setBack("");
      }
    }
  }, [KycRejectionReducer.KycRejection]);

  return (
    <>
      {KycRejectionReducer.loading ? (
        <Card className="flex-center">
          <Spin />{" "}
        </Card>
      ) : (
        <Card>
          {kycStatus === "REJECTED" && (
            <Alert
              type="info"
              message={KycRejectionReducer?.KycRejection?.message}
            />
          )}
          {kycStatus === "SUBMITTED" && (
            <Alert
              type="info"
              message="Your KYC is submitted and is being reviewed."
            />
          )}
          {kycStatus === "VERIFIED" && (
            <Alert type="info" message="Your KYC is already Verified." />
          )}

          {kycStatus === "OTP_PENDING" && (
            <Alert type="info" message="otp of customer is pending." />
          )}
          {kycStatus === "BLOCKED" && (
            <Alert type="info" message="Your Kyc form is blocked by admin" />
          )}
          {(kycStatus === "REJECTED" || kycStatus === "ADD_INFO_PENDING") && (
            <div className="formsignup">
              <Typography.Title
                level={3}
                style={{ color: "#D31413", textAlign: "center" }}
              >
                Know Your Customer (KYC)
              </Typography.Title>
              <h4 style={{ textAlign: "center" }}>Tell us about yourself</h4>
              <Divider />
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                className="signup-form"
              >
                <Row gutter={[8, 8]}>
                  <Col xs={20} sm={20} md={12} lg={8} xl={8}>
                    <Form.Item
                      name="firstName"
                      label="First Name"
                      rules={[
                        { required: true, message: "First Name is required!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder="First Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={8} xl={8}>
                    <Form.Item name="middleName" label="Middle Name">
                      <Input
                        prefix={
                          <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder="MiddleName(Optional)"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={8} xl={8}>
                    <Form.Item
                      name="lastName"
                      label="Last Name"
                      rules={[
                        { required: true, message: "Last Name is required!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <UserOutlined
                            type="user"
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="text"
                        placeholder="Last Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={8} xl={12}>
                    <Form.Item
                      name="streetAddress"
                      label="Street Address"
                      rules={[
                        {
                          required: true,
                          message: "Street Address is required!",
                        },
                      ]}
                    >
                      <Input
                        prefix={
                          <EnvironmentOutlined
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="text"
                        placeholder="Address"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={8} xl={12}>
                    <Form.Item
                      name="suburb"
                      label="Suburb"
                      rules={[
                        { required: true, message: "Suburb is required!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <EnvironmentOutlined
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="text"
                        placeholder="Suburb"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={8} xl={12}>
                    <Form.Item
                      name="state"
                      label="State"
                      rules={[
                        { required: true, message: "State is required!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <CompassOutlined
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="text"
                        placeholder="State"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={8} xl={12}>
                    <Form.Item
                      name="postcode"
                      label="Postcode"
                      rules={[
                        { required: true, message: "Postcode is required!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <EnvironmentOutlined
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        type="text"
                        placeholder="Postcode"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={16} xl={12}>
                    <Form.Item
                      name="phoneNumber"
                      label="Mobile Number"
                      rules={[
                        { min: 9, message: "Min length is 9." },
                        { max: 15, message: "Max length is 15." },
                        {
                          required: true,
                          message: "Please input your Phone number!",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        prefix={
                          <MobileOutlined
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Mobile Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={16} xl={12}>
                    <Form.Item
                      name="gender"
                      label="Gender"
                      rules={[
                        { required: true, message: "Gender is required!" },
                      ]}
                    >
                      <Select placeholder="Gender">
                        <Select.Option value="MALE">Male</Select.Option>
                        <Select.Option value="FEMALE">Female</Select.Option>
                        <Select.Option value="OTHERS">Others</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={16} xl={12}>
                    <Form.Item
                      name="country"
                      label="Country"
                      rules={[
                        { required: true, message: "Country is required!" },
                      ]}
                    >
                      <Select placeholder="Country">
                        <Select.Option value="Australia">
                          Australia
                        </Select.Option>
                        <Select.Option value="Nepal">Nepal</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={16} xl={12}>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        { required: true, message: "Email is required!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        type="email"
                        placeholder="Email Address"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={16} xl={12}>
                    <Form.Item
                      name="identificationType"
                      label="Identification Type"
                      rules={[
                        { required: true, message: "ID type is required!" },
                      ]}
                    >
                      <Select
                        onSelect={(value: any) => setIdentificationType(value)}
                        placeholder="Identification Type"
                      >
                        <Select.Option value="Passport">Passport</Select.Option>
                        <Select.Option value="License">License</Select.Option>
                        <Select.Option value="Citizenship">
                          Government Issued Photo
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={8} xl={6}>
                    <Form.Item
                      name="identificationNo"
                      label="Identification Number"
                      rules={[
                        { required: true, message: "Please input ID number!" },
                      ]}
                    >
                      <Input
                        type="number"
                        prefix={
                          <NumberOutlined
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Identification Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={8} xl={6}>
                    <Form.Item
                      name="dateOfBirth"
                      label="Date of Birth"
                      rules={[
                        {
                          required: true,
                          message: "Date Of Birth is required!",
                        },
                      ]}
                    >
                      <DatePicker
                        format="DD-MM-YYYY"
                        style={{ width: "100%" }}
                        placeholder="Date of Birth"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={8} xl={6}>
                    <Form.Item
                      name="issuedDate"
                      label="ID Issued Date"
                      rules={[
                        {
                          required: true,
                          message: "ID Issued Date is required!",
                        },
                      ]}
                    >
                      <DatePicker
                        format="DD-MM-YYYY"
                        style={{ width: "100%" }}
                        placeholder="Issued Date"
                      />
                    </Form.Item>
                  </Col>
                  {identificationType !== null &&
                    identificationType !== "Citizenship" && (
                      <Col xs={20} sm={20} md={12} lg={8} xl={6}>
                        <Form.Item
                          name="identificationExpireDate"
                          label="ID Expiration Date"
                          rules={[
                            {
                              required: true,
                              message: "Expiry Date is required!",
                            },
                          ]}
                        >
                          <DatePicker
                            format="DD-MM-YYYY"
                            style={{ width: "100%" }}
                            placeholder="Expiry Date"
                          />
                        </Form.Item>
                      </Col>
                    )}
                  <Col xs={20} sm={20} md={12} lg={8} xl={12}>
                    <Form.Item
                      name="issuedFrom"
                      label="Issued From"
                      rules={[
                        { required: true, message: "Issued From is required!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <EnvironmentOutlined
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Issued From"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={6} xl={6}>
                    <Form.Item
                      name="referralCode"
                      label="Referral Code"
                      //rules={[{ required: true, message: "Referral Code is required!" }]}
                    >
                      <Input
                        prefix={
                          <EnvironmentOutlined
                            style={{ color: "rgba(0,0,0,.25)" }}
                          />
                        }
                        placeholder="Referral Code"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[8, 8]}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <p>Identification Image</p>
                  <Col xs={16} sm={16} md={12} lg={8} xl={6}>
                    <Form.Item
                      name="IdentificationFrontImage"
                      label="Identification Front"
                      rules={[
                        {
                          required:
                            // KycRejectionReducer?.KycRejection?.isIdentificationFrontOk === false &&
                            front === "" ? true : false,
                          message: "ID Front is required",
                        },
                      ]}
                      style={{
                        border:
                          KycRejectionReducer?.KycRejection
                            ?.isIdentificationFrontOk === false
                            ? "1px solid tomato"
                            : "",
                        padding: 10,
                      }}
                    >
                      {front === "" && kycStatus === "REJECTED" ? (
                        <>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleChangefront}
                          />
                          {KycRejectionReducer?.KycRejection
                            ?.identificationFrontMessage !== null && (
                            <Alert
                              type="info"
                              message={
                                KycRejectionReducer?.KycRejection
                                  ?.identificationFrontMessage
                              }
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleChangefront}
                          />
                          <Image
                            style={{ marginTop: 10 }}
                            src={front}
                            preview={false}
                            width={100}
                            height="auto"
                          />
                        </>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={20} sm={20} md={12} lg={8} xl={6}>
                    <Form.Item
                      name="IdentificationBackImage"
                      label="Identification back"
                      rules={[
                        {
                          required:
                            // KycRejectionReducer?.KycRejection?.isIdentificationBackOk === false &&
                            back === "" ? true : false,
                          message: "ID Back is required",
                        },
                      ]}
                      style={{
                        border:
                          KycRejectionReducer?.KycRejection
                            ?.isIdentificationBackOk === false
                            ? "1px solid tomato"
                            : "",
                        padding: 10,
                      }}
                    >
                      {back === "" && kycStatus === "REJECTED" ? (
                        <>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleChangeback}
                          />
                          {KycRejectionReducer?.KycRejection
                            ?.identificationBackMessage !== null && (
                            <Alert
                              type="info"
                              message={
                                KycRejectionReducer?.KycRejection
                                  ?.identificationBackMessage
                              }
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleChangeback}
                          />
                          <Image
                            style={{ marginTop: 10 }}
                            src={back}
                            preview={false}
                            width={100}
                            height="auto"
                          />
                        </>
                      )}
                    </Form.Item>
                    <Form.Item
                      name="ProfilePicture"
                      // label="User Image"
                      label="Address Verification Id"
                      rules={[
                        {
                          required:
                            // KycRejectionReducer?.KycRejection?.isProfilePictureOk === false &&
                            profileImage === "" ? true : false,
                          message: "User Image is required",
                        },
                      ]}
                      style={{
                        border:
                          KycRejectionReducer?.KycRejection
                            ?.isProfilePictureOk === false
                            ? "1px solid tomato"
                            : "",
                        padding: 10,
                      }}
                    >
                      {profileImage === "" && kycStatus === "REJECTED" ? (
                        <>
                          <input
                            style={{ width: "100%" }}
                            type="file"
                            accept="image/*"
                            onChange={handleChangeuser}
                          />
                          {KycRejectionReducer?.KycRejection
                            ?.profileImageMessage !== null && (
                            <Alert
                              type="info"
                              message={
                                KycRejectionReducer?.KycRejection
                                  ?.profileImageMessage
                              }
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <input
                            style={{ width: "100%" }}
                            type="file"
                            accept="image/*"
                            onChange={handleChangeuser}
                          />
                          <Image
                            style={{ marginTop: 10 }}
                            src={profileImage}
                            width={100}
                            height="auto"
                            preview={false}
                          />
                        </>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Card>
      )}
    </>
  );
};

export default KYC;

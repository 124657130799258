import { ofType } from "redux-observable";
import { API } from "Redux/Apis";
import { GET_FAQ_FAILURE, GET_FAQ_REQUEST, GET_FAQ_SUCCESS } from "Redux/Constants";
import { flatMap, map } from "rxjs/operators";

const FaqApi = () => API.get("/frequently-asked-question/get-all")
export const FaqEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(GET_FAQ_REQUEST),
        flatMap(async () => {
            try {
                const response = await FaqApi();
                return { payload: response }
            } catch (e) {
                return { error: e }
            }
        }),
        map((action: any) => {
            if (action.payload) {
                return {
                    type: GET_FAQ_SUCCESS,
                    payload: action?.payload,
                };
            } else if (action.error) {
                return {
                    type: GET_FAQ_FAILURE,
                    payload: action?.error?.response?.data
                };
            }
        })
    );

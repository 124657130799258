import { ofType } from "redux-observable";
import { API } from "Redux/Apis";
import { flatMap, map } from "rxjs/operators";
import { GET_SAVED_RECIPIENTS_FAILURE, GET_SAVED_RECIPIENTS_REQUEST, GET_SAVED_RECIPIENTS_SUCCESS } from "./SavedRecipientsActions";


const SavedRecipientsApi = () => API.get("/recipient/get-all")
export const SavedRecipientsEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(GET_SAVED_RECIPIENTS_REQUEST),
        flatMap(async () => {
            try {
                const response = await SavedRecipientsApi();
                return { payload: response }
            } catch (e) {
                return { error: e }
            }
        }),
        map((action: any) => {
            if (action.payload) {
                return {
                    type: GET_SAVED_RECIPIENTS_SUCCESS,
                    payload: action?.payload,
                };
            } else if (action.error) {
                return {
                    type: GET_SAVED_RECIPIENTS_FAILURE,
                    payload: action?.error?.response?.data
                };
            }
        })
    );

import { Reducer } from "redux";
import { GET_SAVED_RECIPIENTS_FAILURE, GET_SAVED_RECIPIENTS_REQUEST, GET_SAVED_RECIPIENTS_SUCCESS } from "./SavedRecipientsActions"

const INIT_STATE = {
    SavedRecipients: false,
    loading: false,
    error: false
}
export const SavedRecipientsReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_SAVED_RECIPIENTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            }
        case GET_SAVED_RECIPIENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                SavedRecipients: action?.payload?.data
            }
        case GET_SAVED_RECIPIENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload?.message
            }
        default:
            return state

    }
}
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Pagination,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { API } from "Redux/Apis";
import { GetLinkedBanksRequest } from "./LinkedBankAccountsActions";

interface LinkedBankAccountsProps {}

var colorCode: any = window.localStorage.getItem("localColorCode");
const retrivedColorCodes = JSON.parse(colorCode);

const LinkedBankAccounts: React.FC<LinkedBankAccountsProps> = () => {
  const [pageNo, setPageNo] = useState<number>(1);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [id, setId] = useState<number>();
  const dispatch = useDispatch();
  const LinkedBankAccountsReducers = useSelector(
    (state: RootStateOrAny) => state.LinkedBankAccountsReducers
  );

  const [form] = useForm();

  const columns: any = [
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      responsive: ["sm"] as Breakpoint[],
    },
    {
      title: "A/C Holder Name",
      dataIndex: "accountHolderName",
      key: "accountHolderName",
    },
    {
      title: "A/C Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
      responsive: ["md"] as Breakpoint[],
    },
    {
      title: "A/C Email",
      dataIndex: "emailAddress",
      key: "emailAddress",
      width: 200,
      ellipsis: true,
      responsive: ["lg"] as Breakpoint[],
    },
    {
      title: "Reason For Sending",
      dataIndex: "reasonForSending",
      key: "reasonForSending",
      width: 150,
      responsive: ["xl"] as Breakpoint[],
    },
    {
      title: "Bank Status",
      align: "center",
      dataIndex: "accociatedBankActiveStatus",
      render: (dataStatus: boolean) => {
        return dataStatus ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      render: (text: any, record: any) => {
        return (
          <Space size="small">
            <Tooltip placement="top" title="Edit Linked Bank">
              <Button
                type="primary"
                onClick={() => {
                  setShowEdit(true);
                  setId(record.id);
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Popconfirm
              title="Are you sure to delete this Bank Account?"
              onConfirm={async () => {
                try {
                  const response = await API.delete(
                    `/customer/link-bank-account/delete/${record.id}`
                  );
                  if (response.status === 200) {
                    notification.success({ message: "Successfully Deleted!" });
                    dispatch(GetLinkedBanksRequest(1));
                  }
                } catch (error) {
                  notification.error({
                    message: "Something Went Wrong! Please try again later.",
                  });
                }
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip placement="top" title="Delete Linked Bank">
                <Button type="primary" style={{ marginLeft: 4 }}>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const handleSaveEdit = async (values: any) => {
    const valuesToSend = {
      ...values,
      id: id,
    };
    try {
      const response = await API.post(
        "/customer/linked-bank-account/edit",
        valuesToSend
      );
      if (response.status === 200) {
        notification.success({ message: "Successfully Saved!" });
        dispatch(GetLinkedBanksRequest(1));
        setShowEdit(false);
      }
    } catch (error: any) {
      notification.error({ message: error?.response?.data?.message });
    }
  };
  const handlePageChange = (e: any) => {
    setPageNo(e);
  };
  useEffect(() => {
    dispatch(GetLinkedBanksRequest(pageNo));
  }, [dispatch, pageNo]);

  useEffect(() => {
    const details =
      LinkedBankAccountsReducers?.LinkedBankAccounts?.bankAccountList?.data?.filter(
        (d: any) => d.id === id
      );
    form.setFieldsValue(details && details[0]);
  }, [showEdit, id]);

  return (
    <Card>
      <Typography.Title
        level={4}
        style={{ color: `${retrivedColorCodes?.primaryColor}` }}
      >
        Linked Bank Accounts
      </Typography.Title>
      <Divider />
      <Modal
        visible={showEdit}
        okText="Save"
        footer={false}
        onCancel={() => setShowEdit(false)}
      >
        <Form form={form} layout="vertical" onFinish={handleSaveEdit}>
          <Form.Item>
            <Typography.Title
              level={4}
              style={{ color: `${retrivedColorCodes?.primaryColor}` }}
            >
              Edit Linked Bank Account
            </Typography.Title>
          </Form.Item>
          <Form.Item
            name="accountHolderName"
            label="Account Holder's Name"
            rules={[
              { required: true, message: "Account Holder's Name is required!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="accountNumber"
            label="Account Number"
            rules={[{ required: true, message: "Account Number is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="branch"
            label="Branch Name"
            // rules={[{ required: true, message: "Branch Name is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="reasonForSending"
            label="Reason For Sending"
            rules={[
              { required: true, message: "Reason For Sending is required!" },
            ]}
          >
            {/* <Input /> */}
            <Select placeholder="Select Reason for Sending">
              <Select.Option value="personal">Personal</Select.Option>
              <Select.Option value="family">Family</Select.Option>
              <Select.Option value="business">Business</Select.Option>
              <Select.Option value="others">Others</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: "First Name is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="middleName" label="Middle Name">
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: "Last Name is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="addressLine"
            label="Address Line 1"
            rules={[{ required: true, message: "Address Line 1 is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="mobileNumber"
            label="Mobile Number"
            rules={[
              { min: 9, message: "Min length is 9." },
              { max: 15, message: "Max length is 15." },
              { required: true, message: "Mobile Number is required!" },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item name="emailAddress" label="Email Address">
            <Input type="email" />
          </Form.Item>
          <Form.Item
            name="city"
            label="City"
            rules={[{ required: true, message: "City is required!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="zone"
            label="Province"
            // rules={[{ required: true, message: "Province is required!" }]}
          >
            <Select placeholder="Select Province">
              <Select.Option value={1}>Province 1</Select.Option>
              <Select.Option value={2}>Province 2</Select.Option>
              <Select.Option value={3}>Province 3</Select.Option>
              <Select.Option value={4}>Province 4</Select.Option>
              <Select.Option value={5}>Province 5</Select.Option>
              <Select.Option value={6}>Province 6</Select.Option>
              <Select.Option value={7}>Province 7</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Table
        style={{ marginTop: 30 }}
        columns={columns}
        pagination={false}
        loading={LinkedBankAccountsReducers?.loading}
        dataSource={
          LinkedBankAccountsReducers?.LinkedBankAccounts?.bankAccountList?.data
        }
      ></Table>
      <Pagination
        style={{ marginTop: 20 }}
        onChange={handlePageChange}
        pageSize={10}
        defaultCurrent={pageNo}
        total={
          LinkedBankAccountsReducers?.LinkedBankAccounts?.bankAccountList
            ?.totalData
        }
      />
    </Card>
  );
};

export default LinkedBankAccounts;

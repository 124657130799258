import { ofType } from "redux-observable";
import { API } from "Redux/Apis";
import {
  GET_BANK_LIST_FAILURE,
  GET_BANK_LIST_REQUEST,
  GET_BANK_LIST_SUCCESS,
} from "Redux/Constants";
import { flatMap, map } from "rxjs/operators";

export const BankListApi = () =>
  API.get("/associated-banks/customer/get?pageSize=100");

export const BankListEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(GET_BANK_LIST_REQUEST),
    flatMap(async () => {
      try {
        const response = await BankListApi();
        return { payload: response };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action: any) => {
      if (action?.payload) {
        return {
          type: GET_BANK_LIST_SUCCESS,
          payload: action?.payload,
        };
      } else if (action.error) {
        return {
          type: GET_BANK_LIST_FAILURE,
          payload: action?.error?.response?.data,
        };
      }
    })
  );

import AuthLayout from "Layouts/AuthLayout";
import { Route, RouteChildrenProps, Switch } from "react-router";
import ForgotPassword from "Views/Auth/ForgotPassword/ForgotPassword";
import Login from "Views/Auth/Login";
import Signup from "Views/Auth/Signup";

const AuthRoute = (props: RouteChildrenProps) => {
  return (
    <AuthLayout>
      <Switch>
        <Route exact path={`${props.match?.path}`} component={Login} />
        <Route exact path={`${props.match?.path}/signup`} component={Signup} />
        <Route exact path={`${props.match?.path}/forgot-password`} component={ForgotPassword} />
      </Switch>
    </AuthLayout>
  );
};
export default AuthRoute;

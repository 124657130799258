import { ofType } from "redux-observable";
import { API } from "Redux/Apis";
import { GET_TERMSANDCONDITIONS_FAILURE, GET_TERMSANDCONDITIONS_REQUEST, GET_TERMSANDCONDITIONS_SUCCESS } from "Redux/Constants";
import { flatMap, map } from "rxjs/operators";


const TermsAndConditionsApi = () => API.get(`/privacy-terms/get-terms-condition?agentCode=${process.env.REACT_APP_API_AGENTCODE_FAKE}`)
export const TermsAndConditionsEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(GET_TERMSANDCONDITIONS_REQUEST),
        flatMap(async () => {
            try {
                const response = await TermsAndConditionsApi();
                return { payload: response }
            } catch (e) {
                return { error: e }
            }
        }),
        map((action: any) => {
            if (action.payload) {
                return {
                    type: GET_TERMSANDCONDITIONS_SUCCESS,
                    payload: action?.payload,
                };
            } else if (action.error) {
                return {
                    type: GET_TERMSANDCONDITIONS_FAILURE,
                    payload: action?.error?.response?.data
                };
            }
        })
    );

import { Card, Divider, Spin, Typography } from "antd";
import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { GetPrivacyPolicyRequest } from "./PrivacyPolicyActions";
import Parser from "html-react-parser";

interface PrivacyPolicyProps {}

var colorCode :any = window.localStorage.getItem('localColorCode');
const retrivedColorCodes = JSON.parse(colorCode)

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = () => {
  const dispatch = useDispatch();
  const PrivacyPolicyReducers = useSelector((state: RootStateOrAny) => state.PrivacyPolicyReducers);

  useEffect(() => {
    if (!PrivacyPolicyReducers.PrivacyPolicy) {
      dispatch(GetPrivacyPolicyRequest());
    }
  }, [dispatch, PrivacyPolicyReducers.PrivacyPolicy]);

  return (
    <Card>
      <Typography.Title level={3} style={{ color: `${retrivedColorCodes?.primaryColor}` }}>
        Privacy & Policy
      </Typography.Title>
      <Divider />
      {PrivacyPolicyReducers?.loading ? (
        <Spin size='large' style={{ marginLeft: "50%" }} />
      ) : (
        <div style={{ height: "auto", width: "100%" }}>
          {PrivacyPolicyReducers?.PrivacyPolicy &&
            Parser(PrivacyPolicyReducers?.PrivacyPolicy?.privacyPolicyList[0]?.termsAndCondition || "")}
        </div>
      )}
    </Card>
  );
};

export default PrivacyPolicy;

import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useParams } from "react-router";
import { API } from "Redux/Apis";

interface EachActivitiesProps {}

export function getWithExpiry(key: string) {
  const itemStr = sessionStorage.getItem(key)
    ? sessionStorage.getItem(key)
    : null;
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item: any = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    sessionStorage.removeItem(key);
    return null;
  }
  return item.value;
}

const style = {
  color: "#F4822A",
  marginTop: 10,
};
const EachActivities: React.FC<EachActivitiesProps> = () => {
  const [ActivityDetail, setActivityDetail] = useState<any>();
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [pdfLink, setPdfLink] = useState<any>();
  const { id } = useParams<any>();
  const PaymentActivities = useSelector(
    (state: RootStateOrAny) => state.ActivitiesReducers.PaymentActivities.data
  );

  useEffect(() => {
    if (id) {
      const ActivityDetail = PaymentActivities?.filter(
        (d: any) => d.paymentId === Number(id)
      );
      setActivityDetail(ActivityDetail[0]);
    }
  }, [id]);

  let myDate = new Date().toString().split("+")[1];
  let myString = `+${myDate.slice(0, 2)}:${myDate.slice(2, 4)}`;

  const handleDownloadPdf = async (id: any) => {
    setPdfLoading(true);
    const reportPdfDoc = await API.get(
      `https://laxmipay.ausnepit.me:8444/remittance/v1/api/transfer/payment/new-format/get-pdf/${id}/${myString}`,
      {
        headers: { Authorization: "Bearer " + getWithExpiry("ABC") },
        responseType: "blob",
      }
    );

    // console.log("The reportddsfcod",reportPdfDoc)
    if (reportPdfDoc?.status === 200) {
      setPdfLoading(false);
      const blob = new Blob([reportPdfDoc.data], {
        type: "application/pdf",
      });

      const computedFileName = `hamroremit_activity_file.pdf`;
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = computedFileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <Card>
      <Row>
        <Col span={24}>
          <Space>
            <Typography.Title
              level={4}
              style={{ color: "#D31413", margin: 0, fontSize: 20 }}
            >
              Activity Detail of Payment
            </Typography.Title>
            <Button type="primary" onClick={() => handleDownloadPdf(id)}>
              <DownloadOutlined />
            </Button>
          </Space>
          <Divider />
        </Col>
        <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
          <Typography.Text>
            Payment Method:{" "}
            <span style={{ color: "#F4822A", fontSize: 18 }}>
              {ActivityDetail?.paymentMethod}
            </span>
          </Typography.Text>
        </Col>
        <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
          <Typography.Text>
            Activity Code:{" "}
            <span style={{ color: "#F4822A", fontSize: 18 }}>
              {ActivityDetail?.activityCode}
            </span>
          </Typography.Text>
        </Col>
        <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
          <Typography.Text>
            Processed By:{" "}
            <span style={{ color: "#F4822A", fontSize: 18 }}>
              {ActivityDetail?.senderMobileNumber}
            </span>
          </Typography.Text>
        </Col>
        <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
          <Typography.Text>
            Sender Name:{" "}
            <span style={{ color: "#F4822A", fontSize: 18 }}>
              {ActivityDetail?.senderName}
            </span>
          </Typography.Text>
        </Col>
        <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
          <Typography.Text>
            Receiver Name:{" "}
            <span style={{ color: "#F4822A", fontSize: 18 }}>
              {ActivityDetail?.receiverName}
            </span>
          </Typography.Text>
        </Col>
        <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
          <Typography.Text>
            Transaction Amount:{" "}
            <span style={{ color: "#F4822A", fontSize: 18 }}>
              AUD {ActivityDetail?.transactionAmount}
            </span>
          </Typography.Text>
        </Col>
        {ActivityDetail?.paymentType === "BANK DEPOSIT" && (
          <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
            <Typography.Text>
              Account Holder Name:{" "}
              <span style={{ color: "#F4822A", fontSize: 18 }}>
                {ActivityDetail?.receiverAccountHolderName}
              </span>
            </Typography.Text>
          </Col>
        )}
        <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
          <Typography.Text>
            Receiving Amount: Nrs.{" "}
            <span style={{ color: "#F4822A", fontSize: 18 }}>
              {ActivityDetail?.receivingAmount}
            </span>
          </Typography.Text>
        </Col>
        <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
          <Typography.Text>
            Transfer Fee: AUD{" "}
            <span style={{ color: "#F4822A", fontSize: 18 }}>
              {ActivityDetail?.transferFee}
            </span>{" "}
          </Typography.Text>
        </Col>
        <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
          <Typography.Text>
            Exchange Rate:{" "}
            <span style={{ color: "#F4822A", fontSize: 18 }}>
              {ActivityDetail?.exchangeRate}
            </span>{" "}
          </Typography.Text>
        </Col>
        {ActivityDetail?.bankName !== null ? (
          <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
            <Typography.Text>
              Bank Name:{" "}
              <span style={{ color: "#F4822A", fontSize: 18 }}>
                {ActivityDetail?.bankName}
              </span>{" "}
            </Typography.Text>
          </Col>
        ) : (
          <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
            <Typography.Text>
              Remittance Branch:{" "}
              <span style={{ color: "#F4822A", fontSize: 18 }}>
                {ActivityDetail?.remittanceBranch}
              </span>{" "}
            </Typography.Text>
          </Col>
        )}
        <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
          <Typography.Text>
            Reason For Sending:{" "}
            <span style={{ color: "#F4822A", fontSize: 18 }}>
              {ActivityDetail?.remarks}
            </span>{" "}
          </Typography.Text>
        </Col>
        <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
          <Typography.Text>
            Status:{" "}
            <span style={{ color: "#F4822A", fontSize: 18 }}>
              {ActivityDetail?.paymentStatus}
            </span>{" "}
          </Typography.Text>
        </Col>
      </Row>
      {pdfLoading && (
        <div className="downloading--loading">
          <div>Downloading ...</div>
        </div>
      )}
    </Card>
  );
};

export default EachActivities;

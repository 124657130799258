import { notification } from "antd"
import { Reducer } from "react"
import { GET_TERMSANDCONDITIONS_FAILURE, GET_TERMSANDCONDITIONS_REQUEST, GET_TERMSANDCONDITIONS_SUCCESS } from "Redux/Constants"

const INIT_STATE = {
    TermsAndConditions: false,
    loading: false,
    error: false
}
export const TermsAndConditionsReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_TERMSANDCONDITIONS_REQUEST:
            return { ...state, loading: true, error: false }
        case GET_TERMSANDCONDITIONS_SUCCESS:
            return { ...state, loading: false, error: false, TermsAndConditions: action?.payload?.data }
        case GET_TERMSANDCONDITIONS_FAILURE: {
            notification.error({ message: action?.payload?.message })
            return { ...state, loading: false, error: action?.payload?.message }
        }
        default:
            return state
    }
}
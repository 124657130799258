import { Reducer } from "react"
import { GET_BANK_LIST_FAILURE, GET_BANK_LIST_REQUEST, GET_BANK_LIST_SUCCESS } from "Redux/Constants"
import { DISCOUNT_USE_LOYALTY_POINTS } from "../SendMoney/PoliPayRedux/PoliPayActions"

const INIT_STATE = {
    BankLists: [],
    loading: false,
    error: false,

}

export const BankListReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {

        case GET_BANK_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_BANK_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                BankLists: action?.payload?.data
            }
        case GET_BANK_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload?.message
            }
        default:
            return state
    }
}
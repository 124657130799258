import { BANK_DEPOSIT_ID, BANK_OR_BRANCH, POST_POLIPAY_REQUEST, RECEIVING_AMOUNT, REMITTANCE_DEPOSIT_ID, TRANSACTION_AMOUNT } from "Redux/Constants";
export const PAYMENT_METHOD = "PAYMENT_METHOD"
export const DELIVERY_NOTIFICATION = "DELIVERY_NOTIFICATION"
export const DISCOUNT_USE_LOYALTY_POINTS = "DISCOUNT_USE_LOYALTY_POINTS"

export const PostPoliPayRequest = (data: any) => ({ type: POST_POLIPAY_REQUEST, payload: data })
// export const PostPoliPaySuccess = (response: any) => ({ type: POST_POLIPAY_REQUEST, payload: response })
// export const PostPoliPayFailure = (error: any) => ({ type: POST_POLIPAY_REQUEST, payload: error })

export const TransactionAmount = (data: any) => ({ type: TRANSACTION_AMOUNT, payload: data })

export const BankOrBranch = (data: any) => ({ type: BANK_OR_BRANCH, payload: data })
export const ReceivingAmount = (data: any) => ({ type: RECEIVING_AMOUNT, payload: data })
export const BankDepositId = (data: any) => ({ type: BANK_DEPOSIT_ID, payload: data })
export const RemittanceDepositId = (data: any) => ({ type: REMITTANCE_DEPOSIT_ID, payload: data })

export const PaymentMethod = (data: any) => ({ type: PAYMENT_METHOD, payload: data })

export const RESET_POLIPAY = "RESET_POLIPAY"

export const ResetPoliPay = () => ({ type: RESET_POLIPAY })


export const DeliveryNotification = (data: any) => ({ type: DELIVERY_NOTIFICATION, payload: data })
export const UseLoyaltyPoints = (data: any) => ({ type: DISCOUNT_USE_LOYALTY_POINTS, payload: data })
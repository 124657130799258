import { Reducer } from "react"
import { GET_EXCHANGERATES_FAILURE, GET_EXCHANGERATES_REQUEST, GET_EXCHANGERATES_SUCCESS, TRIGGER_LOGOUT } from "Redux/Constants"


const INIT_STATE = {
    ExchangeRates: false,
    loading: false,
    error: false
}

export const DashboardReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_EXCHANGERATES_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_EXCHANGERATES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                ExchangeRates: action?.payload?.data
            }
        case GET_EXCHANGERATES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload?.message
            }
        case TRIGGER_LOGOUT:
            return {
                ...state,
                loading: false,
                error: false,
                ExchangeRates: false
            }
        default:
            return state
    }
}
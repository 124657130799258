import { ofType } from "redux-observable";
import { API } from "Redux/Apis";
import { POST_SIGNUP_FAILURE, POST_SIGNUP_REQUEST, POST_SIGNUP_SUCCESS } from "Redux/Constants";
import { flatMap, map } from "rxjs/operators";

export const SignupEmailApi = (data: any) => API.post('/customer/register-email', { email: data.email,agentCode: data.agentCode })
export const SignupPhoneApi = (data: any) => API.post('/customer/register', { callingCode: data.callingCode, mobileNo: data.mobileNo,agentCode: data.agentCode })

export const SignupEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(POST_SIGNUP_REQUEST),
        flatMap(async (action: any) => {
            try {
                if (action.payload.email) {
                    const response = await SignupEmailApi(action.payload);
                    return { payload: response }
                } else {
                    const response = await SignupPhoneApi(action.payload);
                    return { payload: response }
                };
            } catch (e) {
                return { error: e }
            }
        }),
        map((action: any) => {
            if (action.payload) {
                return {
                    type: POST_SIGNUP_SUCCESS,
                    payload: action.payload,
                };
            } else if (action.error) {
                return {
                    type: POST_SIGNUP_FAILURE,
                    payload: action.error.response.data
                };
            }
        })
    );

import { Card, Col, Image, Row, Space, Typography } from "antd";
import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import {
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  FacebookIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WhatsappIcon,
} from "react-share";
import Referral from "Assets/refer.png";
import { GetSupportInfoRequest } from "./ReferralsActions";

interface ReferralsProps {}

const Referrals: React.FC<ReferralsProps> = () => {
  const dispatch = useDispatch();
  const Profile = useSelector(
    (state: RootStateOrAny) => state.LoginReducers.Login.profile
  );
  const SupportInfo = useSelector(
    (state: RootStateOrAny) => state.ReferralsReducers.SupportInfo
  );
  useEffect(() => {
    if (!SupportInfo) {
      dispatch(GetSupportInfoRequest());
    }
  }, []);
  return (
    <Card>
      <Row>
        <Col span={24} className="flex-center">
          <Image src={Referral} width={400} preview={false} />
        </Col>
        <Col span={24} className="flex-center">
          <Space direction="vertical">
            <Space direction="vertical" style={{ textAlign: "center" }}>
              <Typography.Text>Refer friend and earn.</Typography.Text>
              <Typography.Text>
                For every referral, you will get 10 loyalty points and your
                friend will get loyalty points.
              </Typography.Text>
            </Space>
            <Card className="flex-center">
              <Space direction="vertical">
                <Typography.Text>Your unique code:</Typography.Text>
                <Typography.Text style={{ color: "#F4822A" }} copyable>
                  {Profile?.referralCode}
                </Typography.Text>
                <Space direction="horizontal">
                  {/*<FacebookShareButton appId='1' url={Profile?.referralCode}>
                    <FacebookIcon />
                  </FacebookShareButton>*/}
                  <RedditShareButton url={Profile?.referralCode}>
                    <RedditIcon />
                  </RedditShareButton>
                  <TelegramShareButton url={Profile?.referralCode}>
                    <TelegramIcon />
                  </TelegramShareButton>
                  <TwitterShareButton url={Profile?.referralCode}>
                    <TwitterIcon />
                  </TwitterShareButton>
                  <ViberShareButton url={Profile?.referralCode}>
                    <ViberIcon />
                  </ViberShareButton>
                  <VKShareButton url={Profile?.referralCode}>
                    <VKIcon />
                  </VKShareButton>
                </Space>
              </Space>
            </Card>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default Referrals;

import { Content } from "antd/lib/layout/layout";

interface AuthLayoutProps {
  children?: any;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <Content className='flex-center' style={{ padding: "60px 15%" }}>
      {children}
    </Content>
  );
};

export default AuthLayout;

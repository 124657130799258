import { Reducer } from "react"
import { GET_PAYMENT_ACTIVITIES_FAILURE, GET_PAYMENT_ACTIVITIES_REQUEST, GET_PAYMENT_ACTIVITIES_SUCCESS, TRIGGER_LOGOUT } from "Redux/Constants"

const INIT_STATE = {
    PaymentActivities: [],
    loading: false,
    error: false
}

export const ActivitiesReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {

        case GET_PAYMENT_ACTIVITIES_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_PAYMENT_ACTIVITIES_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                PaymentActivities: action?.payload?.data
            }
        }
        case GET_PAYMENT_ACTIVITIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload?.message
            }
        case TRIGGER_LOGOUT:
            return {
                ...state,
                loading: false,
                error: false,
                PaymentActivities: []
            }
        default:
            return state
    }
}
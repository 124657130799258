import { AxiosResponse } from "axios";
import { RootStateOrAny } from "react-redux";
import { Action } from "redux";
import { Epic, ofType } from "redux-observable";
import { API } from "Redux/Apis";
import { POST_LOGIN_FAILURE, POST_LOGIN_REQUEST, POST_LOGIN_SUCCESS } from "Redux/Constants";
import { flatMap, map } from "rxjs/operators";


interface payloadTypes {
    data: {
        username: string,
        password: string,
        agentCode: string,
    }
}
interface Actions {
    type: typeof POST_LOGIN_REQUEST,
    payload: payloadTypes
}
interface LoginReqTypes {
    type: typeof POST_LOGIN_REQUEST
}
export const LoginApi = (data: any) => API.post('/customer/login', { username: data.username, password: data.password,agentCode: data.agentCode })

export const LoginEpic: Epic<Action<Actions>, RootStateOrAny> = (action$, state$) =>
    action$.pipe(
        ofType<Action<LoginReqTypes>, any>(POST_LOGIN_REQUEST),
        flatMap(async (action: any) => {
            try {
                const response: AxiosResponse = await LoginApi(action.payload);
                return { payload: response }
            } catch (e) {
                return { error: e }
            }
        }),
        map((action: any) => {
            if (action?.payload) {
                return {
                    type: POST_LOGIN_SUCCESS,
                    payload: action?.payload,
                };
            } else if (action?.error) {
                return {
                    type: POST_LOGIN_FAILURE,
                    payload: action?.error?.response?.data
                };
            }
        })
    );

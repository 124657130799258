import { Button, Card, Form, Input, notification, Typography } from "antd";
import React from "react";
import { RouteComponentProps } from "react-router";
import { API } from "Redux/Apis";

interface ChangePasswordProps {
  history: RouteComponentProps["history"];
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
};

const ChangePassword: React.FC<ChangePasswordProps> = ({ history }) => {
  const handleChangePassword = async (values: any) => {
    values.agentCode = process.env.REACT_APP_API_AGENTCODE_FAKE
    try {
      const response = await API.post("/customer/change-password", values);
      if (response.status === 200) {
        notification.success({ message: "Change Password Success!" });
        history.push("/my-profile");
      }
    } catch (error:any) {
      notification.error({
        message: "Couldn't Change Password!",
        description: error?.response?.data?.message,
      });
    }
  };

  return (
    <Card>
      <Typography.Title level={4} style={{ color: "#D31413", fontSize: 18 }}>
        Change Password
      </Typography.Title>
      <Form onFinish={handleChangePassword} {...layout}>
        <Form.Item name='currentPassword' label='Current Password'>
          <Input.Password />
        </Form.Item>
        <Form.Item
          name='newPassword'
          label='New Password'
          hasFeedback
          rules={[
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              message:
                "Your password must include at least 1 number, 1 uppercase, 1 lowercase and 1 symbol.",
            },
            { min: 8, message: "Min password length is 8." },
            { max: 15, message: "Max password length is 15." },
            { required: true, message: "Please input your New Password!" },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name='conformPassword'
          label='Confirm Password'
          dependencies={["newPassword"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your New Password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button htmlType='submit' type='primary'  style={{ width: "100%",backgroundColor: "#D31413",color: "white" }}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ChangePassword;

import { ofType } from "redux-observable";
import { API } from "Redux/Apis";
import { GET_PRIVACYPOLICY_FAILURE, GET_PRIVACYPOLICY_REQUEST, GET_PRIVACYPOLICY_SUCCESS } from "Redux/Constants";
import { flatMap, map } from "rxjs/operators";

const PrivacyPolicyApi = () => API.get(`/privacy-terms/get-privacy-policy?agentCode=${process.env.REACT_APP_API_AGENTCODE_FAKE}`)

export const PrivacyPolicyEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(GET_PRIVACYPOLICY_REQUEST),
        flatMap(async () => {
            try {
                const response = await PrivacyPolicyApi();
                return { payload: response }
            } catch (e) {
                return { error: e }
            }
        }),
        map((action: any) => {
            if (action.payload) {
                return {
                    type: GET_PRIVACYPOLICY_SUCCESS,
                    payload: action?.payload,
                };
            } else if (action.error) {
                return {
                    type: GET_PRIVACYPOLICY_FAILURE,
                    payload: action?.error?.response?.data
                };
            }
        })
    );

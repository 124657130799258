import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { ActivitiesReducers } from "Views/Activities/ActivitiesReducers";
import { LoginReducers } from "Views/Auth/LoginRedux/LoginReducers";
import { SignupReducers } from "Views/Auth/SignupRedux/SignupReducers";
import { BankListReducers } from "Views/Dashboard/BankList/BankListReducers";
import { DashboardReducers } from "Views/Dashboard/DashboardReducers";
import { SendLocationReducers } from "Views/Dashboard/SendLocationRedux/SendLocationReducers";
import { PoliPayReducers } from "Views/Dashboard/SendMoney/PoliPayRedux/PoliPayReducers";
import { SenderDetailsReducers } from "Views/Dashboard/SendMoney/SenderDetails/SenderDetailsReduces";
import { SenderInformationReducers } from "Views/Dashboard/SendMoney/SenderInformation/SenderInformationReducers";
import { FAQReducers } from "Views/Faq/FaqReducers";
import { LinkedBankAccountsReducers } from "Views/LinkedBankAccounts/LinkedBankAccountsReducers";
import { PrivacyPolicyReducers } from "Views/PrivacyPolicy/PrivacyPolicyReducers";
import { ReferralsReducers } from "Views/Referrals/ReferralsReducers";
import { SavedRecipientsReducers } from "Views/SavedRecipients/SavedRecipientsReducers";
import { TermsAndConditionsReducers } from "Views/TermsAndConditions/TermsAndConditionsReducers";
import { NotificationReducer } from "./NotificationRedux/NotificationReducers";
import { ProfileReducers } from 'Views/KYC/ProfileReducers';
import { KycRejectionReducers } from 'Views/KYC/KycRejectionRedux/KycRejectionReducer';

const rootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    SignupReducers: SignupReducers,
    LoginReducers: LoginReducers,
    NotificationReducers: NotificationReducer,
    DashboardReducers: DashboardReducers,
    ActivitiesReducers: ActivitiesReducers,
    PrivacyPolicyReducers: PrivacyPolicyReducers,
    TermsAndConditionsReducers: TermsAndConditionsReducers,
    FaqReducers: FAQReducers,
    BankListReducers: BankListReducers,
    PoliPayReducers: PoliPayReducers,
    LinkedBankAccountsReducers: LinkedBankAccountsReducers,
    SavedRecipientsReducers: SavedRecipientsReducers,
    SenderDetailsReducers: SenderDetailsReducers,
    SenderInformationReducers: SenderInformationReducers,
    SendLocationReducers: SendLocationReducers,
    ReferralsReducers: ReferralsReducers,
    ProfileReducers: ProfileReducers,
    KycRejectionReducer: KycRejectionReducers
  });

export default rootReducer;

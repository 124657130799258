import "Themes/App.scss";
import "./App.less";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";
import MainRoute from "Routes/MainRoute";
import { history, persistor, store } from "Redux/ConfigureStore";
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <MainRoute />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;

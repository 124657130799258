import { notification } from "antd"
import { Reducer } from "react"
import { GET_KYC_REJECTION_FAILURE, GET_KYC_REJECTION_REQUEST, GET_KYC_REJECTION_SUCCESS } from "./KycReductionAction"

const INIT_STATE = {
    KycRejection: false,
    loading: false,
    error: false,
}

export const KycRejectionReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_KYC_REJECTION_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_KYC_REJECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                KycRejection: action?.payload?.data
            }
        case GET_KYC_REJECTION_FAILURE: {
            notification.error({ message: "Unable to fetch rejection data." })
            return {
                ...state,
                loading: false,
                error: action?.payload?.message
            }
        }
        default:
            return state
    }
}
import { Card, Divider, Space, Spin, Typography } from "antd";
import React, { FC, useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { GetFAQRequest } from "./FaqActions";

interface FaqProps {}

var colorCode :any = window.localStorage.getItem('localColorCode');
const retrivedColorCodes = JSON.parse(colorCode)

interface QAProps {
  children?: any;
}
const QA: FC<QAProps> = ({ children }) => {
  return (
    <Space direction='vertical'>
      <Typography.Text>Q. {children?.question}</Typography.Text>
      <Typography.Text>A. {children?.answer}</Typography.Text>
    </Space>
  );
};
const Faq: React.FC<FaqProps> = () => {
  const dispatch = useDispatch();
  const FAQs = useSelector((state: RootStateOrAny) => state.FaqReducers);
  useEffect(() => {
    if (FAQs.FAQ.length! >= 0) {
      dispatch(GetFAQRequest());
    }
  }, []);
  return (
    <Card>
      <Typography.Title level={3} style={{ color: `${retrivedColorCodes?.primaryColor}` }}>
        Frequently Asked Questions
      </Typography.Title>
      <Divider />
      {FAQs?.loading ? (
        <Spin size='large' style={{ marginLeft: "50%" }} />
      ) : (
        <div>
          {FAQs?.FAQ?.map((faq: any) => {
            return (
            <>
            {/* <QA key={faq.id}>{faq}</QA> */}
            <div style={{display: "flex",fontSize: "18px"}}>
            <span style={{color: "red"}}>Q : </span><h4 dangerouslySetInnerHTML={{ __html: faq.question }} style={{color: "red"}} />
            </div>
            <div style={{display: "flex",fontSize: "14px",marginTop: "-10px"}}>
            <span>Ans : </span><p dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </div>
            <hr />
            </>
            )
            
          })}
        </div>
      )}
    </Card>
  );
};

export default Faq;

import { ofType } from "redux-observable";
import { API } from "Redux/Apis";
import { GET_PAYMENT_ACTIVITIES_FAILURE, GET_PAYMENT_ACTIVITIES_REQUEST, GET_PAYMENT_ACTIVITIES_SUCCESS } from "Redux/Constants";
import { flatMap, map } from "rxjs/operators";

const ActivitiesApi = (data: any) => API.get(`/transfer/list-payment?startDate=${data.startDate}&endDate=${data.endDate}&page=${data.pageNo}&pageSize=10&sort=descending&sortParameter=id`)
export const ActivitiesEpic = (action$: any, state$: any) =>
    action$.pipe(
        ofType(GET_PAYMENT_ACTIVITIES_REQUEST),
        flatMap(async (action: any) => {
            try {
                const response = await ActivitiesApi(action.payload);
                return { payload: response }
            } catch (e) {
                return { error: e }
            }
        }),
        map((action: any) => {
            if (action.payload) {
                return {
                    type: GET_PAYMENT_ACTIVITIES_SUCCESS,
                    payload: action?.payload,
                };
            } else if (action.error) {
                return {
                    type: GET_PAYMENT_ACTIVITIES_FAILURE,
                    payload: action?.error?.response?.data
                };
            }
        })
    );

import { Reducer } from "redux"
import { GET_LINKED_BANKS_FAILURE, GET_LINKED_BANKS_REQUEST, GET_LINKED_BANKS_SUCCESS } from "./LinkedBankAccountsActions"

const INIT_STATE = {
    LinkedBankAccounts: false,
    loading: false,
    error: false
}
export const LinkedBankAccountsReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_LINKED_BANKS_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_LINKED_BANKS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                LinkedBankAccounts: action?.payload?.data
            }
        case GET_LINKED_BANKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload?.message
            }
        default:
            return state
    }
}
import { Reducer } from "react"
import { GET_PROFILE_FAILURE, GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS } from "./ProfileActions"

const INIT_STATE = {
    ProfileDetails: false,
    loading: false,
    error: false
}

export const ProfileReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            }
        case GET_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                ProfileDetails: action?.payload?.data
            }
        case GET_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload?.message
            }
        default:
            return state
    }
}
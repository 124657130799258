import { Reducer } from "react";
import { GET_PRIVACYPOLICY_FAILURE, GET_PRIVACYPOLICY_REQUEST, GET_PRIVACYPOLICY_SUCCESS } from "Redux/Constants";

const INIT_STATE = {
    PrivacyPolicy: false,
    loading: false,
    error: false
}
export const PrivacyPolicyReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_PRIVACYPOLICY_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_PRIVACYPOLICY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                PrivacyPolicy: action?.payload?.data
            }
        case GET_PRIVACYPOLICY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload?.message
            }
        default:
            return state
    }
}
import { Card, Divider, Spin, Typography } from "antd";
import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { GetTermsAndConditionsRequest } from "./TermsAndConditionsActions";
import Parser from "html-react-parser";

interface TermsAndConditionsProps {}
var colorCode :any = window.localStorage.getItem('localColorCode');
const retrivedColorCodes = JSON.parse(colorCode)

const TermsAndConditions: React.FC<TermsAndConditionsProps> = () => {
  const dispatch = useDispatch();
  const TermsAndConditions = useSelector(
    (state: RootStateOrAny) => state.TermsAndConditionsReducers
  );
  useEffect(() => {
    if (!TermsAndConditions.TermsAndConditions) {
      dispatch(GetTermsAndConditionsRequest());
    }
  }, [dispatch, TermsAndConditions.TermsAndConditions]);
  return (
    <Card>
      <Typography.Title level={3} style={{ color: `${retrivedColorCodes?.primaryColor}` }}>
        Terms And Conditions
      </Typography.Title>
      <Divider />
      {TermsAndConditions?.loading ? (
        <Spin size='large' style={{ marginLeft: "50%" }} />
      ) : (
        <div style={{ marginTop: -20, height: "auto", width: "100%" }}>
          {TermsAndConditions?.TermsAndConditions &&
            Parser(
              TermsAndConditions?.TermsAndConditions?.termsAndConditionList[0]?.termsAndCondition || ""
            )}
        </div>
      )}
    </Card>
  );
};

export default TermsAndConditions;

import { notification } from "antd";
import { Reducer } from "react"
import { POST_LOGIN_REQUEST, POST_LOGIN_SUCCESS, POST_LOGIN_FAILURE, TRIGGER_LOGOUT } from 'Redux/Constants';

const INIT_STATE = {
    Login: false,
    error: false,
    loading: false,
    expiryTime: false
}

export const LoginReducers: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case POST_LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            }
        case POST_LOGIN_SUCCESS: {
            const Token = action.payload.data.accessToken
            return {
                ...state,
                loading: false,
                error: false,
                Login: action?.payload?.data,
                expiryTime: new Date().getTime() + 900000
            }
        }
        case POST_LOGIN_FAILURE: {
            notification.error({
                message: "Login Failed!",
                description: action?.payload?.message
            })
            return {
                ...state,
                loading: false,
                error: true,
            }
        }
        case TRIGGER_LOGOUT:
            return {
                ...state,
                loading: false,
                error: false,
                Login: false,
                expiryTime: false
            }
        default:
            return state
    }
}
import {
  DollarOutlined,
  HomeOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Col, Image, Row, Space, Tabs, Typography } from "antd";
import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

interface ProfileProps {}

var colorCode: any = window.localStorage.getItem("localColorCode");
const retrivedColorCodes = JSON.parse(colorCode);

const style = {
  color: "#F4822A",
  marginTop: 10,
};
const Profile: React.FC<ProfileProps> = () => {
  const LoginReducers = useSelector(
    (state: RootStateOrAny) => state.LoginReducers
  );
  const ProfileData = LoginReducers?.Login.profile;

  return (
    <Card>
      <Row>
        <Col span={12} className="flex-center">
          <Image
            src={ProfileData.profilePicture}
            width={200}
            height={200}
            preview={false}
            style={{ borderRadius: "50%", objectFit: "contain" }}
          />
        </Col>
        <Col span={12} className="flex-center">
          <Row>
            <Col span={24} className="flex-start">
              <UserOutlined style={{ fontSize: 25 }} />{" "}
              <Typography.Text style={{ fontSize: 18, marginLeft: 5 }}>
                {ProfileData?.customerFirstName} {ProfileData?.customerLastName}
              </Typography.Text>
            </Col>
            <Col span={24} className="flex-start">
              <DollarOutlined style={{ fontSize: 25 }} />{" "}
              <Typography.Text style={{ fontSize: 18, marginLeft: 5 }}>
                Loyalty Points: {ProfileData?.loyaltyPoints}
              </Typography.Text>
            </Col>
            <Col span={24} className="flex-start">
              <HomeOutlined style={{ fontSize: 25 }} />{" "}
              <Typography.Text style={{ fontSize: 18, marginLeft: 5 }}>
                Lives in {ProfileData?.streetAddress}, {ProfileData?.suburb},{" "}
                {ProfileData?.state}
              </Typography.Text>
            </Col>
            <Col span={24} className="flex-start">
              <PhoneOutlined style={{ fontSize: 25 }} />
              <Typography.Text style={{ fontSize: 18, marginLeft: 5 }}>
                {" "}
                {ProfileData?.phoneNumber}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ marginTop: 30 }}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Personal Details" key="1">
              <Row>
                <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                  <Typography.Text>
                    First Name:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.customerFirstName}
                    </span>
                  </Typography.Text>
                </Col>
                {ProfileData?.customerMiddleName && (
                  <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                    <Typography.Text>
                      MiddleName Name:{" "}
                      <span
                        style={{
                          color: "#F4822A",
                          fontSize: 18,
                          marginLeft: 5,
                        }}
                      >
                        {ProfileData?.customerMiddleName}
                      </span>
                    </Typography.Text>
                  </Col>
                )}
                <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                  <Typography.Text>
                    Last Name:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.customerLastName}
                    </span>
                  </Typography.Text>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                  <Typography.Text>
                    Date Of Birth:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.dateOfBirth}
                    </span>
                  </Typography.Text>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                  <Typography.Text>
                    Gender:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.gender}
                    </span>
                  </Typography.Text>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                  <Typography.Text>
                    Street Address:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.streetAddress}
                    </span>
                  </Typography.Text>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                  <Typography.Text>
                    Suburb:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.suburb}
                    </span>
                  </Typography.Text>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                  <Typography.Text>
                    State:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.state}
                    </span>
                  </Typography.Text>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                  <Typography.Text>
                    Postcode:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.postcode}
                    </span>
                  </Typography.Text>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                  <Typography.Text>
                    Country:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.country}
                    </span>
                  </Typography.Text>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                  <Typography.Text>
                    Mobile Number:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.phoneNumber}
                    </span>
                  </Typography.Text>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                  <Typography.Text>
                    Email Address:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.email}
                    </span>
                  </Typography.Text>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Document Details" key="2">
              <Row>
                <Col span={24} className="flex-space-between">
                  <Space
                    direction="vertical"
                    style={{
                      display: "felx",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      src={ProfileData?.profileImage}
                      style={{
                        width: "15rem",
                        height: "15rem",
                        objectFit: "contain",
                      }}
                    />
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      Profile Image
                    </span>
                  </Space>
                  <Space
                    direction="vertical"
                    style={{
                      display: "felx",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      src={ProfileData?.identificationFront}
                      style={{
                        width: "15rem",
                        height: "15rem",
                        objectFit: "contain",
                      }}
                    />
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      Document Front
                    </span>
                  </Space>
                  <Space
                    direction="vertical"
                    style={{
                      display: "felx",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      src={ProfileData?.identificationBack}
                      style={{
                        width: "15rem",
                        height: "15rem",
                        objectFit: "contain",
                      }}
                    />
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      Document Back
                    </span>
                  </Space>
                </Col>
                <Col
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={8}
                  style={{ marginTop: 30 }}
                >
                  <Typography.Text>
                    Document Type:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.identificationType}
                    </span>
                  </Typography.Text>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                  <Typography.Text>
                    Document No:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.identificationNumber}
                    </span>
                  </Typography.Text>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                  <Typography.Text>
                    Document Issued From:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.identificationIssuedFrom}
                    </span>
                  </Typography.Text>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                  <Typography.Text>
                    Document Issued Date:{" "}
                    <span
                      style={{ color: "#F4822A", fontSize: 18, marginLeft: 5 }}
                    >
                      {ProfileData?.identificationIssuedOn}
                    </span>
                  </Typography.Text>
                </Col>
                {ProfileData?.identificationExpirationDate && (
                  <Col sm={24} md={12} lg={12} xl={12} xxl={8} style={style}>
                    <Typography.Text>
                      Document Expiry Date:{" "}
                      <span
                        style={{
                          color: "#F4822A",
                          fontSize: 18,
                          marginLeft: 5,
                        }}
                      >
                        {ProfileData?.identificationExpirationDate}
                      </span>
                    </Typography.Text>
                  </Col>
                )}
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Card>
  );
};

export default Profile;

import { Reducer } from "react"
import { GET_SUPPORT_INFO_FAILURE, GET_SUPPORT_INFO_REQUEST, GET_SUPPORT_INFO_SUCCESS } from "./ReferralsActions"

const INIT_STATE = {
    SupportInfo: false,
    loading: false,
    error: false
}
export const ReferralsReducers: Reducer<any, any> = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SUPPORT_INFO_REQUEST:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_SUPPORT_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                SupportInfo: action?.payload?.data
            }
        case GET_SUPPORT_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload?.message
            }
        default:
            return state
    }
}
import { Reducer } from "redux"
import { GET_NOTIFICATION_FAILURE, GET_NOTIFICATION_REQUEST, GET_NOTIFICATION_SUCCESS, } from "Redux/Constants"

const INIT_STATE = {
    Notifications: false,
    loading: false,
    error: false,
}

export const NotificationReducer: Reducer<any, any> = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            }
        case GET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                Notifications: action?.payload?.data
            }
        case GET_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload?.message,
            }


        default:
            return state
    }
}
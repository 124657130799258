import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Col, Row } from "antd";
import React from "react";

interface ErrorProps {}

const Error: React.FC<ErrorProps> = () => {
  return (
    <Row justify='center' align='middle'>
      <Col className='flex-center'>
        <Alert message='Something went wrong! Please try again later!' type='error' showIcon />
      </Col>
    </Row>
  );
};

export default Error;
